import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Grid, TextField, Typography, useTheme } from '@mui/material';
import { ActionButton } from '../buttons/ActionButton';
import { usePapaParse } from 'react-papaparse';

const FormUploadCSV = ({ label, getValues, setValue, field, disabled }) => {
  const theme = useTheme();
  const [uploadMessage, setUploadMessage] = useState('');
  const [selectedFileName, setSelectedFileName] = useState(undefined);

  const value = getValues(field);

  const { readString } = usePapaParse();

  const isDisabled = disabled ?? false;

  const onDrop = useCallback(
    (acceptedFiles) => {
      const reader = new FileReader();
      const file = acceptedFiles[0];
      reader.onabort = () => {
        setUploadMessage('La lettura del file è stata interrotta.');
      };
      reader.onerror = () => {
        setUploadMessage('La lettura del file è fallita.');
      };
      reader.onload = () => {

        readString(reader.result, {
          worker: true,
          header: false,
          delimiter: ';',
          complete: (results) => {
            setSelectedFileName(file.path);
            let rows = results.data;
            rows = rows.filter((x) => x !== '');
            const resString = rows.join(';');
            setValue(field, resString);
          }
        });
      };
      reader.readAsText(file);
    },
    [field, readString, setValue]
  );

  const { getRootProps, getInputProps, isDragActive, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'text/csv': []
    },
    maxSize: 100000000, // 100 mb
    multiple: false,
    onDrop
  });

  const dropZoneStyles = {
    userPdfDropzoneBase: {
      padding: '20px',
      borderWidth: 2,
      borderRadius: 2,
      borderColor: 'gray',
      borderStyle: 'dashed',
      outline: 'none',
      transition: 'border .24s ease-in-out'
    },
    userPdfDropzoneActive: { borderColor: 'gray' },
    userPdfDropzoneFocused: { borderColor: 'gray' },
    userPdfDropzoneAccepted: { borderColor: 'gray' },
    userPdfDropzoneRejected: { borderColor: 'gray' }
  };

  const style = useMemo(
    () => ({
      ...dropZoneStyles.userPdfDropzoneBase,
      ...(isDragActive ? dropZoneStyles.userPdfDropzoneActive : {}),
      ...(isFocused ? dropZoneStyles.userPdfDropzoneFocused : {}),
      ...(isDragAccept ? dropZoneStyles.userPdfDropzoneAccepted : {}),
      ...(isDragReject ? dropZoneStyles.userPdfDropzoneRejected : {})
    }),
    [dropZoneStyles, isDragActive, isFocused, isDragAccept, isDragReject]
  );

  const resetUpload = () => {
    setSelectedFileName('');
    setValue(field, '');
    setUploadMessage('');
  };
  //
  // const getOptions = () => {
  //   let options = {};
  //   if (isRequired !== null) {
  //     options.required = {};
  //     options.required.value = isRequired;
  //     if (isRequired === true) {
  //       options.required.message = 'Campo richiesto';
  //     }
  //   }
  //   return options;
  // };

  return (
    <div aria-disabled={isDisabled} className={isDisabled ? 'is-disabled' : ''}>
      {value === '' && uploadMessage === '' && (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>Trascina il file qui ...</Typography>
          ) : (
            <Typography>Trascina il file qui, o fai click per selezionare un file</Typography>
          )}
        </div>
      )}
      {value !== '' && (
        <>
          <Grid container direction={'row'} spacing={theme.spacing(1)} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12} sm={10}>
              <TextField
                name={`csv-file-name-${name}`}
                key={`csv-file-name-${name}`}
                label={label}
                variant="outlined"
                value={selectedFileName}
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <ActionButton
                color="primary"
                variant="contained"
                action={() => {
                  resetUpload();
                }}
                size="small"
                icon={'reset'}
                iconSize="small"
                tooltip={'Reset'}
                label={'Reset'}
                disabled={false}
                loading={false}
                fullWidth
              />
            </Grid>
          </Grid>
        </>
      )}
      {uploadMessage !== '' && <Typography>{uploadMessage}</Typography>}
    </div>
  );
};

export default FormUploadCSV;
