import { CardModal } from '../layout/CardModal';
import { CardContent, Grid, useTheme } from '@mui/material';
import React from 'react';
import { isDataNull } from '../../utils/common';
import AuditChart from './AuditChart';
import { loggedClassNames } from '../../themes/Styles';

const initCharts = [
  {
    mChartDescription: 'Grado di conformità',
    mChartOutputType: 'gauge',
    mChartData: null,
    columns: 6
  },
  {
    mChartDescription: 'Dettagli punteggio',
    mChartOutputType: 'box',
    mChartData: null,
    columns: 6
  },
  {
    mChartDescription: 'Punteggio aree di valutazione',
    mChartOutputType: 'horizontal-bar',
    mChartData: null,
    columns: 12
  },
  {
    mChartDescription: 'Dettaglio score blocchi',
    mChartOutputType: 'stack',
    mChartData: null,
    columns: 12
  },
  {
    mChartDescription: 'Esito alutazione Gap-Analisys 27001',
    mChartOutputType: 'radar',
    mChartData: null,
    columns: 12
  }
];

export const AuditStats = ({ stats, title, setStats, onCloseAction }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const actualCharts = initCharts.map((ch) => {
    return {
      ...ch,
      mChartData: [...stats]
    };
  });

  const modalOpen = () => {
    return !isDataNull(stats);
  };

  const onClose = () => {
    setStats(null);
    if (onCloseAction) {
      onCloseAction();
    }
  };

  if (!modalOpen()) return <></>;

  return (
    <CardModal modalOpen={modalOpen} onClose={onClose} title={title}>
      <CardContent sx={loggedClasses.detailsContent}>
        <Grid container spacing={theme.spacing(1)}>
          {actualCharts.map((chart, index) => {
            return (
              <Grid item xs={12} md={12} lg={chart.columns} key={`chart-col-${index}`}>
                <AuditChart key={`chart-${index}`} chartObject={chart} />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </CardModal>
  );
};
