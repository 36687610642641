import React, { useEffect, useState } from 'react';
import { CardModal } from '../../../../components/layout/CardModal';
import { CardContent, Divider, Grid, Typography, useTheme } from '@mui/material';
import { FormTextBox } from '../../../../components/form/FormTextBox';
import { loggedClassNames } from '../../../../themes/Styles';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { shareAudit } from '../../../../store/reducers/templates';
import { FormActionBar } from '../../../../components/form/FormActionBar';
import { showCustomAlert } from '../../../../store/reducers/errors';
import FormUploadCSV from '../../../../components/form/FormUploadCSV';

export const ShareAudit = ({ auditID, open, onClose }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();

  const [record, setRecord] = useState({
    mInvolvedPeople: '',
    mAddresses: ''
  });
  const [dataLoaded, setDataLoaded] = useState(false);

  const { register, control, handleSubmit, reset, setValue, getValues } = useForm({ defaultValues: record });

  console.log(auditID);

  useEffect(() => {
    if (dataLoaded) return;
    reset(record);
    setDataLoaded(true);
  }, [dataLoaded, record, reset]);

  //dettaglio

  const onSubmit = (data) => {
    setRecord(data);
    let people = data.mInvolvedPeople.split(';').filter((x) => x !== '');
    const shareBody = {
      mAuditID: auditID,
      mInvolvedPeople: people
    };
    const payload = { body: shareBody, dispatch: dispatch };
    dispatch(shareAudit(payload)).then((res) => {
      if (res.payload.result) {
        const message = {
          severity: 'success',
          message: 'Invio del link effettuato correttamente.'
        };
        dispatch(showCustomAlert(message));
      }
    });
  };

  const onError = (errors, e) => {
    console.log(errors, e);
  };

  return (
    <CardModal modalOpen={open} onClose={onClose} title={'Condividi link'} size={'small'}>
      <CardContent sx={loggedClasses.detailsContent}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12}>
              <Typography>Digitare gli indirizzi separati da ; o caricare il file csv</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Destinatari"
                control={control}
                record={record}
                register={register}
                field="mInvolvedPeople"
                required={true}
                multiline={true}
                lines={4}
                maxLength={100}
              />
            </Grid>
            <Divider />
            <Grid item xl={12}>
              <FormUploadCSV
                label={'File CSV'}
                value={record.mInvolvedPeople}
                getValues={getValues}
                setValue={setValue}
                field="mInvolvedPeople"
                required={false}
              />
            </Grid>
            <Grid item xl={6} md={12}>
              <FormActionBar submitLabel={'Condividi'} submitIcon={'share'} hasCancel={false} />
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </CardModal>
  );
};
