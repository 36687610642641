import { CardContent, Grid, Stack, Typography, useTheme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { columnTypes, defaultColDef, isDataLoading, isDataNull } from '../../../utils/common';
import { FormAutoComplete } from '../../../components/form/FormAutoComplete';
import { FormCheckBox } from '../../../components/form/FormCheckBox';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { ActionsToolBar, AddButton } from '../../../components/grid/ActionsToolbar';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { FormIconPicker } from '../../../components/form/FormIconPicker';
import { CardModal } from '../../../components/layout/CardModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteSystemFunction,
  fetchDomains,
  fetchSystemFunction,
  fetchSystemFunctions,
  updateSystemFunction
} from '../../../store/reducers/registries';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { openDrawer } from '../../../store/reducers/menu';

const SystemFunctions = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  const domains = useSelector((state) => state.registries.domains);
  const systemFunctions = useSelector((state) => state.registries.systemFunctions);

  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    if (!isDataLoading(systemFunctions)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchDomains(payload)).then(() => {
      });
      dispatch(fetchSystemFunctions(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, domains, systemFunctions]);

  //dettaglio

  const onSubmit = (data) => {
    const record = { ...data };
    record.mPageSize = parseInt(record.mPageSize);
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateSystemFunction(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const addAction = () => {
    const payload = { body: { mFunctionID: 0 }, dispatch: dispatch };
    dispatch(fetchSystemFunction(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      const payload = { body: { mFunctionID: selectedRow.mFunctionID }, dispatch: dispatch };
      dispatch(fetchSystemFunction(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const deleteAction = () => {
      const payload = { body: { ...selectedRow }, dispatch: dispatch };
      dispatch(deleteSystemFunction(payload)).then(() => {
        setDataLoaded(false);
      });
    };

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} />;
  };

  const [columns] = useState([
    { headerName: 'Funzione', field: 'mFunctionName' },
    { headerName: 'Descrizione', field: 'mFunctionDescription' },
    { headerName: 'Dominio', field: 'mDomain.mDomainName' },
    { headerName: 'URL', field: 'mComponentName' },
    { headerName: 'SQLName', field: 'mSQLName' },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolBar' }
  ]);

  if (isDataNull(systemFunctions) || isDataLoading(systemFunctions)) return <CircularWaiting />;
  // if (isDataNull(functionalDomains) || isDataLoading(functionalDomains)) return <CircularWaiting />;

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Schermate</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pb: theme.spacing(2) }}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)}
                     sx={{ pt: theme.spacing(1) }}>
                <AddButton addAction={addAction} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            {dataLoaded && !isDataLoading(systemFunctions) ? (
              <AgGridReact
                rowData={systemFunctions}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio schermata'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'}
                alignItems={'flex-start'}>
            <Grid item xs={12}>
              <FormTextBox
                label="Nome"
                control={control}
                record={record}
                register={register}
                field="mFunctionName"
                required={true}
                minLength={3}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Descrizione"
                control={control}
                record={record}
                register={register}
                field="mFunctionDescription"
                required={true}
                maxLength={200}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="URL"
                control={control}
                record={record}
                register={register}
                field="mComponentName"
                required={true}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12}>
              <FormAutoComplete
                label="Dominio"
                control={control}
                record={record}
                register={register}
                field="mDomain"
                joinField="mDomainID"
                values={domains}
                idField="mDomainID"
                labelField="mDomainName"
                selectedValue="id"
                required={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormIconPicker
                label="Icona"
                control={control}
                record={record}
                register={register}
                field="mImageKey"
                joinField=""
                required={true}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Record per pagina"
                control={control}
                record={record}
                register={register}
                field="mPageSize"
                required={true}
                maxLength={50}
                pattern={'number'}
              />
            </Grid>
            <Grid item xs={12}>
              <FormCheckBox
                label="Mostra su desktop"
                control={control}
                record={record}
                register={register}
                field="mShowOnDesktop"
                required={false}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
    </>
  );
};

export default SystemFunctions;
