import { CardContent, Grid, Stack, useTheme } from '@mui/material';
import { gridExtraSizeStyle, gridRowHeight, loggedClassNames } from '../../../../themes/Styles';
import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { booleanRendererWithBadge, columnTypes, defaultColDef, gridLoadingOverlay, isDataLoading } from '../../../../utils/common';
import { CardModal } from '../../../../components/layout/CardModal';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsToolBar, AddButton } from '../../../../components/grid/ActionsToolbar';
import { QuestionModal } from './QuestionModal';
import { deleteBlockQuestion, fetchBlockQuestions, resetAnswerItems, updateBlockQuestion } from '../../../../store/reducers/templates';
import { CircularWaiting } from '../../../../components/waitings/CircularWaiting';

export const BlockQuestions = ({ template, block, open, onClose }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  const questions = useSelector((state) => state.templates.blockQuestions);

  const [questionID, setQuestionID] = useState(-1);
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded) return;
    if (!isDataLoading(questions)) {
      const payload = { body: { mBlock: { ...block } }, dispatch: dispatch };
      dispatch(fetchBlockQuestions(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, questions, block]);

  const onQuestionModalClose = () => {
    setQuestionModalOpen(false);
    setDataLoaded(false);
    setQuestionID(-1);
  };

  //griglia

  const saveQuestion = (question) => {
    const record = {
      ...question,
      mBlock: { ...block },
      mAuditTemplate: { ...template }
    };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateBlockQuestion(payload)).then(() => {
      onQuestionModalClose();
    });
  };

  const addAction = () => {
    setQuestionID(0);
    setQuestionModalOpen(true);
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      setQuestionID(selectedRow.mQuestionTemplateID);
      dispatch(resetAnswerItems());
      setQuestionModalOpen(true);
    };

    const deleteAction = () => {
      const body = {
        mQuestionTemplateID: selectedRow.mQuestionTemplateID
      };
      const payload = { body: body, dispatch: dispatch };
      dispatch(deleteBlockQuestion(payload)).then(() => {
        onQuestionModalClose();
      });
    };

    const moveUp = () => {
      const question = { ...selectedRow };
      question.mOrder = Math.max(question.mOrder - 1, 1);
      saveQuestion(question);
    };

    const moveDown = () => {
      const question = { ...selectedRow };
      question.mOrder = Math.min(question.mOrder + 1, questions.length);
      saveQuestion(question);
    };

    const customActions = [
      {
        action: moveUp,
        disabled: false,
        icon: 'arrow-up',
        tooltip: 'Sposta su',
        color: 'primary'
      },
      {
        action: moveDown,
        disabled: false,
        icon: 'arrow-down',
        tooltip: 'Sposta giù',
        color: 'primary'
      }
    ];

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} customActions={customActions} />;
  };

  const columns = [
    { headerName: 'Ordine', field: 'mOrder', type: 'flex1' },
    { headerName: 'Testo', field: 'mQuestionText', type: 'flex3' },
    { headerName: 'Tipo', field: 'mAnswerTypeHumanReadable', type: 'flex1' },
    { headerName: 'Obbligatoria', field: 'mIsMandatory', cellRenderer: booleanRendererWithBadge, type: 'flex1' },
    { headerName: 'Validata', field: 'mIsValidated', cellRenderer: booleanRendererWithBadge, type: 'flex1' },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolButton' }
  ];

  return (
    <>
      <CardModal modalOpen={open} onClose={onClose} title={'Domande del blocco'} size={'small'}>
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12}>
              <Grid container sx={{ pb: theme.spacing(2) }}>
                <Grid item xs={12}>
                  <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                    <AddButton addAction={addAction} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div id="agReactGridQuestions" className="ag-theme-alpine" style={gridExtraSizeStyle}>
                {dataLoaded && !isDataLoading(questions) ? (
                  <AgGridReact
                    rowData={questions}
                    overlayLoadingTemplate={gridLoadingOverlay}
                    defaultColDef={defaultColDef}
                    columnDefs={columns}
                    columnTypes={columnTypes}
                    suppressMovableColumns={true}
                    suppressCellFocus={true}
                    pagination={true}
                    rowSelection={'single'}
                    getRowHeight={() => gridRowHeight}
                  />
                ) : (
                  <CircularWaiting />
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
      {questionModalOpen && (
        <QuestionModal open={questionModalOpen} template={template} block={block} questionID={questionID} onClose={onQuestionModalClose} />
      )}
    </>
  );
};
