import { FormAutoComplete } from '../../../../../components/form/FormAutoComplete';
import React from 'react';

export const ItemizedListAnswer = ({ question, answersForm, answersRecord }) => {
  return (
    <FormAutoComplete
      label={''}
      control={answersForm.control}
      record={answersRecord}
      register={answersForm.register}
      field={'q-' + question.mQuestionTemplateID}
      joinField=""
      values={question.mListOfAnswerItem}
      idField="mAnswerItemID"
      labelField="mAnswerItemText"
      selectedValue="id"
      disabled={false}
      required={question.mIsMandatory ?? false}
      fullWidth
      focused={true}
    />
  );
};
