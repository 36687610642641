import React from 'react';
import { useTheme } from '@mui/material/styles';
import { loggedClassNames } from '../../themes/Styles';

export const PDFViewer = ({ dataType, dataContent }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  return (
    <object data={dataContent} type={dataType} style={loggedClasses.documentPreview}>
      <p>Il tuo browser non riesce a visualizzare il PDF.</p>
      <p>
        <a href={dataContent}>Clicca qui per scaricare il file.</a>
      </p>
    </object>
  );
};
