import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormTextBox } from '../../../../components/form/FormTextBox';
import { FormSubmitButton } from '../../../../components/form/FormSubmitButton';
import { useDispatch, useSelector } from 'react-redux';
import { showConfirmDialog, showCustomAlert } from '../../../../store/reducers/errors';
import { changePassword, logout } from '../../../../store/reducers/auth';
import { openDrawer } from '../../../../store/reducers/menu';
import { useNavigate } from 'react-router-dom';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const changeStatus = useSelector((state) => state.auth.changePasswordStatus) ?? {};
  const authStatus = useSelector((state) => state.auth.authStatus) ?? {};
  const loggedUser = authStatus.loggedUser ?? {};

  const { busy } = useSelector((state) => state.errors);
  const [record, setRecord] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    setRecord(changeStatus);
    reset(changeStatus);
    setDataLoaded(true);
  }, [dataLoaded, changeStatus, reset, dispatch]);

  const onConfirmChange = (data) => {
    const payload = {
      body: {
        mUserID: loggedUser.mUserID,
        mPassword: data.mNewPassword
      },
      dispatch: dispatch
    };
    dispatch(changePassword(payload)).then((res) => {
      if (res.payload.result.anyError) return;
      const logoutPayload = { body: {}, dispatch: dispatch };
      dispatch(logout(logoutPayload)).then((res) => {
        if (!res.payload.result.anyError) navigate('/login');
      });
    });
  };

  const onSubmit = (data) => {
    if (data.mRepeatNewPassword !== data.mNewPassword) {
      const message = {
        severity: 'error',
        message: 'Attenzione! Le due password devono coincidere.'
      };
      dispatch(showCustomAlert(message));
      return;
    }
    const confirm = {
      title: 'Cambio password',
      message: 'Confermando il cambio password la sessione verrà conclusa.',
      handleHide: () => {},
      handleCancel: () => {},
      handleConfirm: () => {
        onConfirmChange(data);
      }
    };
    dispatch(showConfirmDialog(confirm));
  };

  const onError = (errors, e) => {
    console.log(errors, e);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container direction={'row'} spacing={2}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Modifica password</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormTextBox
                  label="Password attuale"
                  control={control}
                  record={record}
                  register={register}
                  field="mCurrentPassword"
                  required={true}
                  maxLength={50}
                  pattern={'password'}
                  disabled={busy}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextBox
                  label="Nuova password"
                  control={control}
                  record={record}
                  register={register}
                  field="mNewPassword"
                  required={true}
                  maxLength={50}
                  pattern={'password'}
                  disabled={busy}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextBox
                  label="Ripeti password"
                  control={control}
                  record={record}
                  register={register}
                  field="mRepeatNewPassword"
                  required={true}
                  maxLength={50}
                  pattern={'password'}
                  disabled={busy}
                />
              </Grid>
              <Grid item xs={12}>
                <FormSubmitButton label={busy ? '...' : 'Cambia'} tooltip={'Cambia password'} disabled={busy} loading={busy} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ChangePassword;
