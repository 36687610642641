import { useTheme } from '@mui/material/styles';
import { loggedClassNames } from '../../../themes/Styles';
import { useState } from 'react';
import { Box, Grid, Tab } from '@mui/material';
import { IconLibraryItem } from '../../../components/icons/IconLibraryItem';
import EditProfile from './components/EditProfile';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ChangePassword from './components/ChangePassword';

const Profile = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const [selectedTab, setSelectedTab] = useState('edit-profile');

  const tabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        sx={loggedClasses.userDetailHeader}
        key="user-profile-tab"
      >
        <Grid item xs={12} mt={2}>
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <TabContext value={selectedTab}>
              <TabList
                orientation="vertical"
                variant="scrollable"
                textColor={'primary'}
                indicatorColor={'primary'}
                onChange={tabChange}
                aria-label="basic tabs example"
                value={selectedTab}
                sx={loggedClasses.userTabButtons}
              >
                <Tab value={'edit-profile'} icon={<IconLibraryItem name={'account'} iconSize={'medium'} />} label="Modifica profilo" />
                <Tab value={'change-password'} icon={<IconLibraryItem name={'key'} iconSize={'medium'} />} label="Modifica password" />
              </TabList>
              <TabPanel value={'edit-profile'} index={0} sx={loggedClasses.userTabPanel}>
                <EditProfile />
              </TabPanel>
              <TabPanel value={'change-password'} index={1} sx={loggedClasses.userTabPanel}>
                <ChangePassword />
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
