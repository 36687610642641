import { FormControl } from '@mui/material';
import React from 'react';
import { ActionButton } from '../buttons/ActionButton';

export const FormCancelButton = ({ label, icon, disabled, action, fullWidth, sx, ...props }) => {
  let isDisabled = disabled ?? false;
  let sIcon = icon ?? 'cancel';

  return (
    <FormControl fullWidth={fullWidth}>
      <ActionButton
        color="primary"
        variant="contained"
        action={action}
        size="medium"
        icon={sIcon}
        iconSize="small"
        tooltip={label}
        label={label}
        disabled={isDisabled}
        fullWidth={fullWidth ?? false}
        sx={sx}
        {...props}
      />
    </FormControl>
  );
};
