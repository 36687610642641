import { CardContent, Grid, Typography, useTheme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  auditStatusRendererWithBadge,
  columnTypes,
  dateColumnRenderer,
  defaultColDef,
  isDataLoading,
  isDataNull,
  nameWithBadgeRenderer,
  textWithBadgeRenderer
} from '../../../utils/common';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { ActionsToolBar } from '../../../components/grid/ActionsToolbar';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { CardModal } from '../../../components/layout/CardModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  completeAudit,
  deleteAudit,
  downloadAudit,
  fetchAudits,
  setAuditToOpen,
  statsAudit,
  updateAudit
} from '../../../store/reducers/templates';
import { openDrawer } from '../../../store/reducers/menu';
import { useNavigate } from 'react-router-dom';
import { PDFContainer } from '../../../components/pdf/PDFContainer';
import { AuditStats } from '../../../components/charts/AuditStats';
import { showConfirmDialog } from '../../../store/reducers/errors';
import { ShareAudit } from './components/ShareAudit';

const Audits = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const audits = useSelector((state) => state.templates.audits);

  const [record] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [stats, setStats] = useState(null);
  const [pdfContent, setPdfContent] = useState('');
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [recordToShare, setRecordToShare] = useState(-1);

  const { register, control, handleSubmit } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    if (!isDataLoading(audits)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchAudits(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, audits]);

  //dettaglio

  const onSubmit = (data) => {
    const record = { ...data };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateAudit(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const mCanBeDeleted = selectedRow.mCanBeDeleted ?? false;
    const mDownloadReports = selectedRow.mDownloadReports ?? false;
    const mCanCompile = selectedRow.mCanCompile ?? false;
    const mShowDashboard = selectedRow.mShowDashboard ?? false;
    const mCanShare = selectedRow.mCanShare ?? false;
    const mCanBeClosed = selectedRow.mCanBeClosed ?? false;

    console.log(!mCanBeDeleted);

    const pdfAction = () => {
      const payload = { body: { ...selectedRow }, dispatch: dispatch };
      dispatch(downloadAudit(payload)).then((res) => {
        setPdfContent(res.mListOfBytes ?? '');
        alert('open pdf');
      });
    };

    const statsAction = () => {
      const payload = { body: { ...selectedRow }, dispatch: dispatch };
      dispatch(statsAudit(payload)).then((res) => {
        const stats = res.payload.result;
        setStats(stats);
      });
    };

    const deleteAction = () => {
      const confirm = {
        title: 'Cancellazione audit',
        message: "Attenzione! Questa azione comporterà la cancellazione dell'audit. Procedere?",
        handleHide: () => {},
        handleCancel: () => {},
        handleConfirm: () => {
          const payload = { body: { ...selectedRow }, dispatch: dispatch };
          dispatch(deleteAudit(payload)).then(() => {
            setDataLoaded(false);
          });
        }
      };
      dispatch(showConfirmDialog(confirm));
    };

    const fillAction = () => {
      dispatch(setAuditToOpen({ mAudit: { ...selectedRow } }));
      navigate('/Templates/FillAuditByGrid');
    };

    const shareAction = () => {
      setRecordToShare(selectedRow.mAuditID);
      setShareModalOpen(true);
    };

    const closeAction = () => {
      const confirm = {
        title: 'Chiusura Audit',
        message: 'Attenzione! Chiudendo questo audit non sarà più possibile compilarlo. Procedere?',
        handleHide: () => {},
        handleCancel: () => {},
        handleConfirm: () => {
          const bodyComplete = {
            mAuditID: selectedRow.mAuditID
          };
          const payloadComplete = { body: bodyComplete, dispatch: dispatch };
          dispatch(completeAudit(payloadComplete)).then(() => {
            setDataLoaded(false);
          });
        }
      };
      dispatch(showConfirmDialog(confirm));
    };

    const customActions = [
      {
        action: pdfAction,
        disabled: !mDownloadReports,
        icon: 'pdf',
        tooltip: 'Scarica report',
        color: 'info'
      },
      {
        action: fillAction,
        disabled: !mCanCompile,
        icon: 'list',
        tooltip: 'Compila',
        color: 'primary'
      },
      {
        action: statsAction,
        disabled: !mShowDashboard,
        icon: 'chart',
        tooltip: 'Statistiche',
        color: 'primary'
      },
      {
        action: shareAction,
        disabled: !mCanShare,
        icon: 'link',
        tooltip: 'Condividi',
        color: 'primary'
      },
      {
        action: closeAction,
        disabled: !mCanBeClosed,
        icon: 'close',
        tooltip: 'Chiudi',
        color: 'primary'
      }
    ];

    return (
      <ActionsToolBar hasEdit={false} hasDelete deleteAction={deleteAction} deleteDisabled={!mCanBeDeleted} customActions={customActions} />
    );
  };

  const [columns] = useState([
    { headerName: 'ID', field: 'mAuditName' },
    {
      headerName: 'Cliente',
      cellRenderer: (props) => {
        const company = props.node.data.mCompany ?? { mCompanyName: '' };
        return textWithBadgeRenderer(company.mCompanyName, 'warning');
      },
      type: 'statusColumn'
    },
    { headerName: 'Stato', field: 'mAuditStatus', cellRenderer: auditStatusRendererWithBadge, type: 'flex1' },
    {
      headerName: 'Data',
      field: 'mAuditDate',
      cellRenderer: dateColumnRenderer,
      type: 'flex1'
    },
    {
      headerName: 'Autore',
      cellRenderer: (props) => {
        const author = props.node.data.mAuthor ?? { mFirstName: '', mLastName: '' };
        return nameWithBadgeRenderer(author.mFirstName, author.mLastName);
      },
      type: 'statusColumn'
    },
    { headerName: 'Prodotto', field: 'mAuditTemplate.mProduct.mProductName' },
    // {
    //   headerName: 'Schema',
    //   field: 'mAuditTemplate.mAuditTemplateName',
    //   type: 'flex1'
    // },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolbar' }
  ]);

  const onShareModalClose = () => {
    setRecordToShare(-1);
    setShareModalOpen(false);
  };

  if (isDataNull(audits) || isDataLoading(audits)) return <CircularWaiting />;

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Lista Audit</Typography>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            {dataLoaded && !isDataLoading(audits) ? (
              <AgGridReact
                rowData={audits}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio audit'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12}>
              <FormTextBox
                label="Nome"
                control={control}
                record={record}
                register={register}
                field="mAuditName"
                required={true}
                maxLength={200}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Descrizione"
                control={control}
                record={record}
                register={register}
                field="mProductName"
                required={true}
                maxLength={1024}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Descrizione tecnica"
                control={control}
                record={record}
                register={register}
                field="mTechDescription"
                required={true}
                maxLength={8192}
                multiline={true}
                lines={10}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Barcode"
                control={control}
                record={record}
                register={register}
                field="mBarcode"
                required={true}
                maxLength={45}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
      {pdfContent && <PDFContainer title={'Anteprima audit'} setContent={setPdfContent} content={pdfContent} />}
      {!isDataNull(stats) && <AuditStats title={'Statistiche audit'} stats={stats} setStats={setStats} />}
      {shareModalOpen && <ShareAudit auditID={recordToShare} open={shareModalOpen} onClose={onShareModalClose} />}
    </>
  );
};

export default Audits;
