import { CardContent, Grid, Stack, Typography, useTheme } from '@mui/material';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { openDrawer } from '../../../store/reducers/menu';
import { defaultColDef, isDataLoading, JSONIsEmpty, SYSTEM_ADMIN } from '../../../utils/common';
import {
  deleteDepartment,
  fetchDepartment,
  fetchDepartments,
  updateDepartment
} from '../../../store/reducers/registries';
import { ActionsToolBar, AddButton } from '../../../components/grid/ActionsToolbar';
import { AgGridReact } from 'ag-grid-react';
import { CardModal } from '../../../components/card/CardModal';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { showConfirmDialog } from '../../../store/reducers/errors';

const Departments = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  const departments = useSelector((state) => state.registries.departments);

  const authStatus = useSelector((state) => state.auth.authStatus) ?? {};
  const loggedUser = authStatus.loggedUser ?? {};
  const loggedProfile = (loggedUser.mProfile ?? {}).mSQLName ?? '';

  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    if (!isDataLoading(departments)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchDepartments(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, departments]);

  //dettaglio

  const onSubmit = (data) => {
    // registriesContext.updateCompany(data);
    const record = { ...data };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateDepartment(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const addAction = () => {
    const payload = { body: { mDepartmentID: 0 }, dispatch: dispatch };
    dispatch(fetchDepartment(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      const payload = { body: { mDepartmentID: selectedRow.mDepartmentID }, dispatch: dispatch };
      dispatch(fetchDepartment(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const deleteAction = () => {
      const confirm = {
        title: 'Cancellazione dipartimento',
        message: 'Attenzione! Questa azione comporterà la cancellazione del dipartimento. Procedere?',
        handleHide: () => {},
        handleCancel: () => {},
        handleConfirm: () => {
          const payload = { body: { mDepartmentID: selectedRow.mDepartmentID }, dispatch: dispatch };
          dispatch(deleteDepartment(payload)).then(() => {
            setModalOpen(false);
            setDataLoaded(false);
          });
        }
      };
      dispatch(showConfirmDialog(confirm));
    };

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} />;
  };

  let initColumns = [{ headerName: 'Nome', field: 'mDepartmentName' }];

  if (loggedProfile === SYSTEM_ADMIN) {
    initColumns.push({ headerName: 'Company', field: 'mCompany.mCompanyName' });
  }

  initColumns = [...initColumns, { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolbar' }];

  const [columns] = useState(initColumns);

  const columnTypes = {
    toolButton: { sortable: false, editable: false, filter: '', width: '100', suppressSizeToFit: true }
  };

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Dipartimenti</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pb: theme.spacing(2) }}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                <AddButton addAction={addAction} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            {dataLoaded && !isDataLoading(departments) ? (
              <AgGridReact
                rowData={departments}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio dipartimento'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
        size={'small'}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          {!JSONIsEmpty(record) ? (
            <>
              <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="Nome"
                    control={control}
                    record={record}
                    register={register}
                    field="mDepartmentName"
                    required={true}
                    maxLength={100}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <CircularWaiting />
          )}
        </CardContent>
      </CardModal>
    </>
  );
};

export default Departments;
