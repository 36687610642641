import { CardContent, Grid, useTheme } from '@mui/material';
import { loggedClassNames } from '../../../../themes/Styles';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isDataLoading } from '../../../../utils/common';
import { CardModal } from '../../../../components/layout/CardModal';
import { FormTextBox } from '../../../../components/form/FormTextBox';
import { fetchAnswerItem, updateAnswerItem } from '../../../../store/reducers/templates';

export const AnswerModal = ({ questionID, questionType, answerID, open, onClose }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();

  const [record, setRecord] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    if (!isDataLoading(record)) {
      const payload = { body: { mAnswerItemID: answerID }, dispatch: dispatch };
      dispatch(fetchAnswerItem(payload)).then((res) => {
        const rec = res.payload.result;
        setRecord(rec);
        reset(rec);
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, record, questionID, reset, answerID]);

  //dettaglio

  const handleClose = () => {
    setDataLoaded(false);
    onClose();
  };

  const onSubmit = (data) => {
    const record = {
      ...data,
      mScore: parseInt(data.mScore),
      mQuestionTemplate: { mQuestionTemplateID: questionID }
    };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateAnswerItem(payload)).then(() => {
      handleClose();
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  // export const answerType = [
  //   { mAnswerTypeID: 1, mAnswerTypeName: 'ItemizedList' },
  //   { mAnswerTypeID: 2, mAnswerTypeName: 'Boolean' },
  //   { mAnswerTypeID: 3, mAnswerTypeName: 'NumericUpDown' },
  //   { mAnswerTypeID: 4, mAnswerTypeName: 'Text' }
  // ];

  const textDisabled = questionType !== 1;

  return (
    <CardModal
      modalOpen={open}
      onClose={handleClose}
      title={'Dettaglio risposta'}
      size={'small'}
      formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
    >
      <CardContent sx={loggedClasses.detailsContent}>
        <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
          <Grid item xs={12}>
            <FormTextBox
              label="Testo"
              control={control}
              record={record}
              register={register}
              field="mAnswerItemText"
              required={true}
              maxLength={100}
              multiline={true}
              lines={2}
              disabled={textDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextBox
              label="Valore"
              control={control}
              record={record}
              register={register}
              field="mScore"
              required={true}
              maxLength={100}
              pattern={'number'}
            />
          </Grid>
        </Grid>
      </CardContent>
    </CardModal>
  );
};
