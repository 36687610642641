import { useRoutes } from 'react-router-dom';

// project import
import NotLoggedRoutes from './NotLoggedRoutes';
import LoggedRoutes from './LoggedRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([LoggedRoutes, NotLoggedRoutes]);
}
