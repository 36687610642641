import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

export const FormCheckBox = ({ ...props }) => {
  const getOptions = () => {
    let options = {};
    if (props.required !== null) {
      options.required = {};
      options.required.value = props.required;
      if (props.required === true) {
        options.required.message = 'Campo richiesto';
      }
    }
    return options;
  };

  const isDisabled = () => {
    return props.disabled ? props.disabled : false;
  };

  return (
    <FormControl fullWidth>
      <Controller
        name={props.field}
        control={props.control}
        render={({ field: { onChange, onBlur, value, name, ref } }) => (
          <FormControlLabel
            name={`form-control-label-${name}`}
            key={`form-control-label-${name}`}
            label={props.label}
            // helperText={error ? error.message : null}
            control={
              <Checkbox
                name={`checkbox-${name}`}
                key={`checkbox-${name}`}
                inputRef={ref}
                checked={value ?? false}
                // indeterminate={checked[0] !== checked[1]}
                onChange={(e) => {
                  let newValue = e.target.checked ?? false;
                  onChange(newValue);
                }}
                onBlur={onBlur}
                sx={{ ...props.sx }}
                disabled={isDisabled()}
              />
            }
          />
        )}
        {...props.register(props.field, getOptions())}
      />
    </FormControl>
  );
};
