import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DoWebApiPostCall } from '../../services/AxiosInstance';
import { getConfigKey, JSONClone } from '../../utils/common';
import {
  API_KEY_SESSION_KEY,
  AXIOS_CALL_RESULT_SESSION_KEY,
  AXIOS_CONFIG_SESSION_KEY,
  getSessionValue,
  LOGGED_USER_SESSION_KEY,
  setSessionValue
} from '../../utils/storage';

export const initAuthRecord = {};

// const maxSessionTime = process.env.REACT_APP_SESSION_DURATION * 60 * 1000;
// const sessionCheckInterval = process.env.REACT_APP_SESSION_CHECK * 1000;

const maxSessionTime = getConfigKey('sessionDurationMins') * 60 * 1000;
const sessionCheckInterval = getConfigKey('sessionCheckSecs') * 1000;

const session = getSessionValue(LOGGED_USER_SESSION_KEY, '');

const DefaultAuthStatus = {
  isLogged: false,
  isExpired: false,
  loggedUser: {},
  sessionRemainingTime: 0
};

const ExpiredAuthStatus = {
  isLogged: false,
  isExpired: true,
  loggedUser: {},
  responseMessage: '',
  sessionRemainingTime: 0
};

const DefaultChangePasswordStatus = {
  mCurrentPassword: '',
  mNewPassword: '',
  mRepeatNewPassword: ''
};

const DefaultLoginStatus = {
  mUsername: '',
  mPassword: ''
};

const DefaultRegisterStatus = {
  mUserName: '',
  mFirstName: '',
  mLastName: '',
  mCompany: {
    mCodiceCliente: ''
  },
  mProfile: {
    mProfileID: 0
  },
  mMobilePhone: ''
};

const DefaultRecoveryStatus = {
  mUserName: ''
};

// initial state
let initialState = {
  authStatus: JSONClone(DefaultAuthStatus),
  loginStatus: JSONClone(DefaultLoginStatus),
  registerStatus: JSONClone(DefaultRegisterStatus),
  changePasswordStatus: JSONClone(DefaultChangePasswordStatus),
  recoveryPasswordStatus: JSONClone(DefaultRecoveryStatus),
  availableProfiles: null,
  maxSessionTime: maxSessionTime,
  sessionCheckInterval: sessionCheckInterval,
  encryptedAuthRes: null
};

if (session !== '') {
  initialState.authStatus = {
    ...initialState.authStatus,
    isLogged: true,
    loggedUser: session
  };
}

export const changePassword = createAsyncThunk('auth/changePassword', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/ChangePassword', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const login = createAsyncThunk('auth/login', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  setSessionValue(LOGGED_USER_SESSION_KEY, '');
  try {
    const res = await DoWebApiPostCall('/Users/DoLogin', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const anonymousLogin = createAsyncThunk('auth/anonymousLogin', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  setSessionValue(LOGGED_USER_SESSION_KEY, '');
  try {
    const res = await DoWebApiPostCall('/Users/AnonymousLogin', body, payload.dispatch, true);
    if (res.anyError) rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const fetchAvailableProfiles = createAsyncThunk('registries/fetchAvailableProfiles', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Profile/GetAvailableProfile', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const registerUser = createAsyncThunk('auth/registerUser', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/Register', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const logout = createAsyncThunk('auth/logout', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/DoLogout', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const ping = createAsyncThunk('auth/ping', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/Ping', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const getLoggedUserProfile = createAsyncThunk('auth/getLoggedUserProfile', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/GetByObject', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const recoveryPassword = createAsyncThunk('auth/recoveryPassword', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/RememberPassword', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateLoggedUserProfile = createAsyncThunk('auth/updateLoggedUserProfile', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/CreateOrUpdate', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

export const updateLoggedUserProfileImage = createAsyncThunk('auth/updateLoggedUserProfileImage', async (payload, { rejectWithValue }) => {
  const body = { ...payload.body };
  try {
    const res = await DoWebApiPostCall('/Users/SetUserImage', body, payload.dispatch, true);
    if (res.anyError) return rejectWithValue(res);
    return { result: res };
  } catch (err) {
    return rejectWithValue({ result: err.response.data });
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSessionExpired(state) {
      state.authStatus = JSONClone(ExpiredAuthStatus);
      setSessionValue(API_KEY_SESSION_KEY, '');
      setSessionValue(LOGGED_USER_SESSION_KEY, '');
      setSessionValue(AXIOS_CONFIG_SESSION_KEY, '');
      setSessionValue(AXIOS_CALL_RESULT_SESSION_KEY, '');
    },
    setSessionRemainingTime(state, action) {
      state.authStatus = {
        ...state.authStatus,
        sessionRemainingTime: action.payload.remainingTime
      };
    },
    refreshChangePasswordState(state, action) {
      state.changePasswordStatus = { ...action.payload };
    },
    refreshRecoveryPasswordState(state, action) {
      state.recoveryPasswordStatus = { ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableProfiles.pending, (state) => {
      state.availableProfiles = 'loading';
    });
    builder.addCase(fetchAvailableProfiles.fulfilled, (state, { payload }) => {
      const res = payload.result;
      state.availableProfiles = [...res.responseData];
    });
    builder.addCase(registerUser.fulfilled, () => {});
    builder.addCase(changePassword.fulfilled, () => {
      refreshChangePasswordState(JSONClone(DefaultChangePasswordStatus));
    });
    builder.addCase(getLoggedUserProfile.fulfilled, (state, { payload }) => {
      const res = payload.result;
      if (res.responseData.length > 0) {
        const user = res.responseData[0];
        const newLoggedUser = {
          ...getSessionValue(LOGGED_USER_SESSION_KEY, ''),
          loggedUser: user
        };
        setSessionValue(LOGGED_USER_SESSION_KEY, newLoggedUser);
        state.authStatus = {
          ...JSONClone(DefaultAuthStatus),
          isLogged: true,
          loggedUser: res.responseData[0],
          responseMessage: res.responseMessage,
          sessionRemainingTime: maxSessionTime
        };
      } else {
        setSessionValue(LOGGED_USER_SESSION_KEY, '');
        state.authStatus = {
          ...JSONClone(DefaultAuthStatus),
          isLogged: false,
          loggedUser: {},
          sessionRemainingTime: 0
        };
      }
    });
    builder.addCase(login.pending, (state) => {
      state.authStatus = {
        ...JSONClone(DefaultAuthStatus)
      };
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      const res = payload.result;
      let apiKey = res.responseApiKey ? res.responseApiKey : '';
      setSessionValue(API_KEY_SESSION_KEY, apiKey);
      if (res.responseData.length > 0) {
        setSessionValue(LOGGED_USER_SESSION_KEY, res.responseData[0]);
        state.authStatus = {
          ...JSONClone(DefaultAuthStatus),
          isLogged: true,
          loggedUser: res.responseData[0],
          responseMessage: res.responseMessage,
          sessionRemainingTime: maxSessionTime
        };
      } else {
        setSessionValue(LOGGED_USER_SESSION_KEY, '');
        state.authStatus = {
          ...JSONClone(DefaultAuthStatus),
          isLogged: false,
          loggedUser: {},
          sessionRemainingTime: 0
        };
      }
    });
    builder.addCase(anonymousLogin.pending, (state) => {
      state.authStatus = {
        ...JSONClone(DefaultAuthStatus)
      };
    });
    builder.addCase(anonymousLogin.fulfilled, (state, { payload }) => {
      const res = payload.result;
      let apiKey = res.responseApiKey ? res.responseApiKey : '';
      setSessionValue(API_KEY_SESSION_KEY, apiKey);
      if (res.responseData.length > 0) {
        setSessionValue(LOGGED_USER_SESSION_KEY, res.responseData[0]);
        state.authStatus = {
          ...JSONClone(DefaultAuthStatus),
          isLogged: true,
          loggedUser: null,
          responseMessage: res.responseMessage,
          sessionRemainingTime: maxSessionTime
        };
      } else {
        setSessionValue(LOGGED_USER_SESSION_KEY, '');
        state.authStatus = {
          ...JSONClone(DefaultAuthStatus),
          isLogged: false,
          loggedUser: {},
          sessionRemainingTime: 0
        };
      }
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.authStatus = {
        ...JSONClone(DefaultAuthStatus)
      };
      setSessionValue(API_KEY_SESSION_KEY, '');
      setSessionValue(LOGGED_USER_SESSION_KEY, '');
      setSessionValue(AXIOS_CONFIG_SESSION_KEY, '');
      setSessionValue(AXIOS_CALL_RESULT_SESSION_KEY, '');
    });
    builder.addCase(logout.rejected, (state) => {
      state.authStatus = {
        ...JSONClone(DefaultAuthStatus)
      };
      setSessionValue(API_KEY_SESSION_KEY, '');
      setSessionValue(LOGGED_USER_SESSION_KEY, '');
      setSessionValue(AXIOS_CONFIG_SESSION_KEY, '');
      setSessionValue(AXIOS_CALL_RESULT_SESSION_KEY, '');
    });
    builder.addCase(ping.fulfilled, () => {});
    builder.addCase(updateLoggedUserProfile.fulfilled, () => {});
    builder.addCase(updateLoggedUserProfileImage.fulfilled, () => {});
    builder.addCase(recoveryPassword.fulfilled, () => {
      refreshRecoveryPasswordState(JSONClone(DefaultRecoveryStatus));
    });
  }
});

export const { setSessionExpired, setSessionRemainingTime, refreshChangePasswordState, refreshRecoveryPasswordState } = authSlice.actions;
export default authSlice.reducer;
