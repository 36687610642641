import { CardContent, Grid } from '@mui/material';
import React from 'react';
import { CardModal } from '../layout/CardModal';
import { PDFViewer } from './PDFViewer';

export const PDFContainer = ({ content, title, setContent, onCloseAction }) => {
  const modalOpen = () => {
    return content !== '';
  };

  const onClose = () => {
    setContent('');
    if (onCloseAction) {
      onCloseAction();
    }
  };

  if (!modalOpen()) return <></>;

  return (
    <CardModal modalOpen={modalOpen} onClose={onClose} title={title}>
      <CardContent>
        {content !== '' && (
          <Grid container>
            <Grid item xs={12}>
              <PDFViewer dataType={'application/pdf'} dataContent={'data:application/pdf;base64,' + content} />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </CardModal>
  );
};
