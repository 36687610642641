import { Autocomplete, createFilterOptions, FormControl, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { isDataLoading } from '../../utils/common';

export const FormAutoComplete = ({
  itemsCount,
  focused,
  matchFrom,
  values,
  field,
  label,
  control,
  idField,
  labelField,
  joinField,
  selectedValue,
  required,
  disabled,
  placeholder,
  ...props
}) => {
  values = values ?? [];
  if (isDataLoading(values)) values = [];

  itemsCount = values.length > 20 ? itemsCount ?? 20 : itemsCount ?? values.length;

  const searchMode = matchFrom ?? 'start'; //any per fare ricerca parziale

  const textPlaceholder = placeholder ?? '';

  let items = [];
  if (idField && labelField) {
    values.map((item) => {
      const idValue = item[idField];
      const labelValue = item[labelField];
      items.push({ id: idValue, label: labelValue });
      return true;
    });
  }

  const getOptions = () => {
    let options = {};
    if (required !== null) {
      options.required = {};
      options.required.value = required;
      if (required === true) {
        options.required.message = 'Campo richiesto';
      }
    }
    return options;
  };

  const isDisabled = () => {
    return disabled ?? false;
  };

  const normalize = (value) => {
    return (value ?? '').toString().trim().toUpperCase();
  };

  const valuesAreEqual = (value1, value2) => {
    return normalize(value1) === normalize(value2);
  };

  const findItemByValue = (value) => {
    return items.find((item) => valuesAreEqual(item[selectedValue], value));
  };

  const getSelectedValue = (value, onChange) => {
    if (!value) {
      return value;
    }

    let foundItem = null;

    if ((joinField ?? '') !== '') {
      foundItem = findItemByValue(value[joinField]);
    } else {
      foundItem = findItemByValue(value);
    }

    const result = foundItem ? foundItem.label : null;

    if (items.length > 1 || !required) return result;

    if (result) return result;

    const defaultItem = items[0];

    let newValue = null;

    if (defaultItem && (joinField ?? '') !== '') {
      newValue = value ?? {};
      newValue[joinField] = defaultItem ? defaultItem[selectedValue] : null;
    } else {
      newValue = defaultItem ? defaultItem[selectedValue] : null;
    }

    onChange(newValue);

    return defaultItem ? defaultItem.label : '';
  };

  const defaultFilterOptions = createFilterOptions({
    matchFrom: searchMode,
    stringify: (option) => normalize(option.label)
  });

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, itemsCount);
  };

  return (
    <FormControl fullWidth>
      <Controller
        name={field}
        control={control}
        render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
          <Autocomplete
            name={`auto-complete-${name}`}
            key={`auto-complete-${name}`}
            disablePortal
            options={items}
            value={getSelectedValue(value, onChange) || null}
            filterOptions={filterOptions}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => {
              if (!value) return false;
              return (joinField ?? '') !== ''
                ? valuesAreEqual(value[joinField] === option[selectedValue])
                : valuesAreEqual(value === option[selectedValue]);
            }}
            onChange={(event, item) => {
              let newValue = null;
              if (item && (joinField ?? '') !== '') {
                newValue = value ?? {};
                newValue[joinField] = item ? item[selectedValue] : null;
              } else {
                newValue = item ? item[selectedValue] : null;
              }
              onChange(newValue);
            }}
            sx={{ ...props.sx }}
            autoHighlight
            autoSelect
            placeholder={textPlaceholder}
            renderInput={(params) => (
              <TextField
                name={`auto-complete-text-field-${name}`}
                key={`auto-complete-text-field-${name}`}
                variant="outlined"
                label={label}
                inputRef={ref}
                focused={focused}
                {...params}
                error={!!error}
                placeholder={textPlaceholder}
                helperText={error ? error.message : null}
              />
            )}
            disabled={isDisabled()}
          />
        )}
        {...props.register(field, getOptions())}
      />
    </FormControl>
  );
};
