import { CardContent, Grid, Stack, Typography, useTheme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  columnTypes,
  defaultColDef,
  isDataLoading,
  isDataNull,
  nameWithBadgeRenderer,
  onLineRendererWithBadge,
  SYSTEM_ADMIN
} from '../../../utils/common';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { ActionsToolBar, AddButton } from '../../../components/grid/ActionsToolbar';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { CardModal } from '../../../components/layout/CardModal';
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer } from '../../../store/reducers/menu';
import { FormAutoComplete } from '../../../components/form/FormAutoComplete';
import { TemplateBlocks } from './components/TemplateBlocks';
import {
  cloneTemplate,
  createAudit,
  fetchProducts,
  fetchTemplate,
  fetchTemplates,
  switchTemplate,
  updateTemplate
} from '../../../store/reducers/templates';
import { showConfirmDialog, showCustomAlert } from '../../../store/reducers/errors';
import { useNavigate } from 'react-router-dom';

const Templates = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.templates.templates);
  const products = useSelector((state) => state.templates.products);

  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [blocksRecord, setBlocksRecord] = useState(null);
  const [blocksModalOpen, setBlocksModalOpen] = useState(false);

  const authStatus = useSelector((state) => state.auth.authStatus) ?? {};
  const loggedUser = authStatus.loggedUser ?? {};
  const loggedProfile = (loggedUser.mProfile ?? {}).mSQLName ?? '';

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    if (!isDataLoading(products)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchProducts(payload)).then(() => {});
    }
    if (!isDataLoading(templates)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchTemplates(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, templates, products]);

  //dettaglio

  const onSubmit = (data) => {
    const record = { ...data };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateTemplate(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const addAction = () => {
    const payload = { body: { mAuditTemplateID: 0 }, dispatch: dispatch };
    dispatch(fetchTemplate(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const mCanBeEdited = selectedRow.mCanBeEdited ?? false;
    const mCanBeCloned = selectedRow.mCanBeCloned ?? false;
    const mCanCreateAudit = selectedRow.mNewAuditFromThisTemplate ?? true;

    const editAction = () => {
      const payload = { body: { mAuditTemplateID: selectedRow.mAuditTemplateID }, dispatch: dispatch };
      dispatch(fetchTemplate(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const questionsAction = () => {
      setBlocksRecord(selectedRow);
      setBlocksModalOpen(true);
    };

    const cloneAction = () => {
      const confirm = {
        title: 'Clonazione template',
        message: 'Attenzione! Stai clonando il template' + selectedRow.mAuditTemplateName + '. Procedere?',
        handleHide: () => {},
        handleCancel: () => {},
        handleConfirm: () => {
          const payload = { body: { ...selectedRow }, dispatch: dispatch };
          dispatch(cloneTemplate(payload)).then(() => {
            setDataLoaded(false);
          });
        }
      };
      dispatch(showConfirmDialog(confirm));
    };

    // const deleteAction = () => {
    //   const payload = { body: { ...selectedRow }, dispatch: dispatch };
    //   dispatch(deleteTemplate(payload)).then(() => {
    //     setDataLoaded(false);
    //   });
    // };

    const switchAction = () => {
      const payload = { body: { ...selectedRow }, dispatch: dispatch };
      dispatch(switchTemplate(payload)).then(() => {
        setDataLoaded(false);
      });
    };

    const newAuditAction = () => {
      const bodyCreate = {
        mCompany: { ...loggedUser.mCompany },
        mAuditTemplate: {
          ...selectedRow
        },
        mAudit: { mAuditID: 0 }
      };
      const payloadCreate = { body: bodyCreate, dispatch: dispatch };
      dispatch(createAudit(payloadCreate)).then((res) => {
        if (!res.payload.result) {
          const message = {
            severity: 'error',
            message: "Errore durante la creazione dell'audit"
          };
          dispatch(showCustomAlert(message));
          return;
        }
        navigate('/Templates/Audits');
      });
    };

    const customActions = [
      {
        action: questionsAction,
        disabled: !mCanBeEdited,
        icon: 'question-mark',
        tooltip: 'Blocchi'
      },
      {
        action: switchAction,
        disabled: !mCanBeEdited,
        icon: selectedRow.mStatus === 'OnLine' ? 'toggle-on' : 'toggle-off',
        tooltip: 'Online/Offline'
      },
      {
        action: cloneAction,
        disabled: !mCanBeCloned,
        icon: 'copy',
        tooltip: 'Clona'
      },
      {
        action: newAuditAction,
        disabled: !mCanCreateAudit,
        icon: 'add',
        tooltip: 'Nuovo Audit'
      }
    ];

    return <ActionsToolBar hasEdit editAction={editAction} editDisabled={!mCanBeEdited} hasDelete={false} customActions={customActions} />;
  };

  const [columns] = useState([
    { headerName: 'Codice', field: 'mAuditTemplateCode' },
    { headerName: 'Nome', field: 'mAuditTemplateName' },
    {
      headerName: 'Autore',
      cellRenderer: (props) => {
        const author = props.node.data.mAuthor ?? { mFirstName: '', mLastName: '' };
        return nameWithBadgeRenderer(author.mFirstName, author.mLastName);
      },
      type: 'statusColumn'
    },
    { headerName: 'Stato', field: 'mStatus', cellRenderer: onLineRendererWithBadge, type: 'statusColumn' },
    { headerName: 'Prodotto', field: 'mProduct.mDescription' },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolbar' }
  ]);

  const onBlocksClose = () => {
    setBlocksModalOpen(false);
    setBlocksRecord(null);
  };

  if (isDataNull(templates) || isDataLoading(templates)) return <CircularWaiting />;

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Template di Audit</Typography>
        </Grid>
        {loggedProfile === SYSTEM_ADMIN && (
          <Grid item xs={12}>
            <Grid container sx={{ pb: theme.spacing(2) }}>
              <Grid item xs={12}>
                <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                  <AddButton addAction={addAction} />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            {dataLoaded && !isDataLoading(templates) ? (
              <AgGridReact
                rowData={templates}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio template'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={6}>
              <FormAutoComplete
                label="Prodotto"
                control={control}
                record={record}
                register={register}
                field="mProduct"
                joinField="mProductID"
                values={products}
                idField="mProductID"
                labelField="mProductName"
                selectedValue="id"
                required={false}
              />
            </Grid>
            <Grid item xs={6}>
              <FormTextBox
                label="Codice"
                control={control}
                record={record}
                register={register}
                field="mAuditTemplateCode"
                required={true}
                maxLength={20}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Nome"
                control={control}
                record={record}
                register={register}
                field="mAuditTemplateName"
                required={true}
                maxLength={200}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
      {blocksModalOpen && <TemplateBlocks open={blocksModalOpen} template={blocksRecord} onClose={onBlocksClose} />}
    </>
  );
};

export default Templates;
