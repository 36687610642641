import { CardContent, Grid, Stack, Typography, useTheme } from '@mui/material';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../../themes/Styles';
import { useDispatch, useSelector } from 'react-redux';
import { FormTextBox } from '../../../../components/form/FormTextBox';
import { FormAutoComplete } from '../../../../components/form/FormAutoComplete';
import { answerType, columnTypes, defaultColDef, isDataLoading, isDataNull } from '../../../../utils/common';
import { FormCheckBox } from '../../../../components/form/FormCheckBox';
import { StyledCardHeader } from '../../../../components/card/SyledCardHeader';
import { AgGridReact } from 'ag-grid-react';
import { CardModal } from '../../../../components/layout/CardModal';
import React, { useEffect, useState } from 'react';
import {
  deleteAnswerItem,
  fetchAnswerItems,
  fetchBlockQuestion,
  insertISOAnswers,
  resetAnswerItems,
  updateBlockQuestion
} from '../../../../store/reducers/templates';
import { useForm } from 'react-hook-form';
import { ActionsToolBar, AddButton } from '../../../../components/grid/ActionsToolbar';
import { AnswerModal } from '../../audits/components/AnswerModal';
import { showConfirmDialog } from '../../../../store/reducers/errors';
import { ActionButton } from '../../../../components/buttons/ActionButton';
import { CircularWaiting } from '../../../../components/waitings/CircularWaiting';

export const QuestionModal = ({ template, block, questionID, open, onClose }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();

  const answerItems = useSelector((state) => state.templates.answerItems);

  const [record, setRecord] = useState(null);
  const [oldTypeID, setOldTypeID] = useState(-1);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [answersEnabled, setAnswersEnabled] = useState(false);

  const [answerID, setAnswerID] = useState(-1);
  const [answerModalOpen, setAnswerModalOpen] = useState(false);

  const { register, control, handleSubmit, reset, watch, getValues, setValue } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    const body = { mQuestionTemplateID: questionID };
    const payload = { body: { ...body }, dispatch: dispatch };
    dispatch(fetchBlockQuestion(payload)).then((res) => {
      const rec = res.payload.result;
      setRecord(rec);
      reset(rec);
      const oldType = rec.mAnswerTypeID;
      setOldTypeID(oldType);
      setAnswersEnabled(oldType === 1); // || oldType === 2
    });
  }, [dataLoaded, dispatch, questionID, reset]);

  useEffect(() => {
    if (dataLoaded) return;
    if (isDataNull(record)) return;
    if (isDataLoading(answerItems)) return;
    //caricare risposte
    const payloadAnswers = { body: { mQuestionTemplate: { mQuestionTemplateID: questionID } }, dispatch: dispatch };
    dispatch(fetchAnswerItems(payloadAnswers)).then(() => {
      setDataLoaded(true);
    });
  }, [answerItems, dataLoaded, dispatch, questionID, record]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (type === 'change' && name === 'mAnswerTypeID') {
        let changeValues = getValues();
        const newTypeID = changeValues.mAnswerTypeID;
        // setAnswersEnabled(newTypeID === 1 || newTypeID === 2);
        setAnswersEnabled(false);
        if (newTypeID !== oldTypeID && (oldTypeID === 1 || oldTypeID === 2)) {
          const confirm = {
            title: 'Cambio tipo domanda',
            message: 'Attenzione! Questa azione comporterà il reset di tutte le risposte associate dopo il salvataggio. Procedere?',
            handleHide: () => {},
            handleCancel: () => {
              setValue('mAnswerTypeID', oldTypeID);
            },
            handleConfirm: () => {}
          };
          dispatch(showConfirmDialog(confirm));
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [dispatch, getValues, oldTypeID, setValue, watch]);

  //dettaglio

  const handleClose = () => {
    dispatch(resetAnswerItems());
    setDataLoaded(false);
    onClose();
  };

  const onSubmit = (data) => {
    const record = {
      ...data,
      mBlock: { ...block },
      mAuditTemplate: { ...template }
    };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateBlockQuestion(payload)).then(() => {
      handleClose();
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const addAction = () => {
    setAnswerID(0);
    setAnswerModalOpen(true);
  };

  const ToolBarAnswers = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      setAnswerID(selectedRow.mAnswerItemID);
      setAnswerModalOpen(true);
    };

    const deleteAction = () => {
      const payload = { body: { mAnswerItemID: selectedRow.mAnswerItemID }, dispatch: dispatch };
      dispatch(deleteAnswerItem(payload)).then(() => {
        setDataLoaded(false);
      });
    };

    return (
      <ActionsToolBar
        hasEdit
        editDisabled={!answersEnabled}
        deleteDisabled={!answersEnabled}
        editAction={editAction}
        hasDelete
        deleteAction={deleteAction}
      />
    );
  };

  const columnsAnswers = [
    { headerName: 'Testo', field: 'mAnswerItemText', type: 'flex3' },
    { headerName: 'Valore', field: 'mScore', type: 'flex1' },
    { headerName: 'Successiva', field: '', type: 'flex1' },
    { headerName: 'Azioni', cellRenderer: ToolBarAnswers, type: 'toolButton' }
  ];

  const onAnswerModalClose = () => {
    setAnswerModalOpen(false);
    setDataLoaded(false);
    setAnswerID(-1);
  };

  const addIsoDisabled = !record || !record.mAnswerTypeID || record.mAnswerTypeID !== 1 || !answersEnabled;

  const insertISO = () => {
    const payloadAnswers = { body: { mQuestionTemplate: { mQuestionTemplateID: questionID } }, dispatch: dispatch };
    dispatch(insertISOAnswers(payloadAnswers)).then(() => {
      setDataLoaded(false);
    });
  };

  const insertISOConfirm = () => {
    const confirm = {
      title: 'Inserimento risposte ISO27001',
      message: 'Attenzione! Questa azione comporterà il reset di tutte le risposte associate dopo il salvataggio. Procedere?',
      handleHide: () => {},
      handleCancel: () => {},
      handleConfirm: insertISO
    };
    dispatch(showConfirmDialog(confirm));
  };

  return (
    <>
      <CardModal
        modalOpen={open}
        onClose={handleClose}
        title={'Dettaglio domanda'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12}>
              <FormTextBox
                label="Testo"
                control={control}
                record={record}
                register={register}
                field="mQuestionText"
                required={true}
                maxLength={100}
                multiline={true}
                lines={2}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Suggerimento"
                control={control}
                record={record}
                register={register}
                field="mTooltipText"
                required={true}
                maxLength={100}
                multiline={true}
                lines={2}
              />
            </Grid>
            <Grid item xs={6}>
              <FormAutoComplete
                label="Tipo"
                control={control}
                record={record}
                register={register}
                field="mAnswerTypeID"
                joinField=""
                values={answerType}
                idField="mAnswerTypeID"
                labelField="mAnswerTypeName"
                selectedValue="id"
                required={true}
              />
            </Grid>
            <Grid item xs={6}>
              <FormCheckBox
                label="Obbligatoria"
                control={control}
                record={record}
                register={register}
                field="mIsMandatory"
                required={false}
              />
            </Grid>
            {questionID !== 0 && (
              <>
                <Grid item xs={12}>
                  <StyledCardHeader>
                    <Typography variant={'h5'} component={'span'} justifyContent={'center'}>
                      Risposte
                    </Typography>
                  </StyledCardHeader>
                </Grid>
                <Grid item xs={12}>
                  <Grid container sx={{ pb: theme.spacing(2) }}>
                    <Grid item xs={12}>
                      <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                        <AddButton addAction={addAction} disabled={!answersEnabled} />
                        <ActionButton
                          color="primary"
                          variant="contained"
                          action={insertISOConfirm}
                          size="medium"
                          icon="new"
                          iconSize="small"
                          tooltip={'Inserisci risposte ISO27001'}
                          label={'Inserisci ISO27001'}
                          disabled={addIsoDisabled}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div id="agReactGridAnswers" className="ag-theme-alpine" style={gridSizeStyle}>
                    {dataLoaded && !isDataLoading(answerItems) ? (
                      <AgGridReact
                        rowData={answerItems}
                        defaultColDef={defaultColDef}
                        columnDefs={columnsAnswers}
                        columnTypes={columnTypes}
                        suppressMovableColumns={true}
                        suppressCellFocus={true}
                        pagination={true}
                        rowSelection={'single'}
                        getRowHeight={() => gridRowHeight}
                      />
                    ) : (
                      <CircularWaiting />
                    )}
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </CardModal>
      {answerModalOpen && (
        <AnswerModal
          open={answerModalOpen}
          template={template}
          block={block}
          questionID={questionID}
          questionType={record.mAnswerTypeID}
          answerID={answerID}
          onClose={onAnswerModalClose}
        />
      )}
    </>
  );
};
