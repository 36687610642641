import React, { useEffect, useState } from 'react';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { columnTypes, defaultColDef, isDataLoading, isDataNull, JSONIsEmpty } from '../../../utils/common';
import { ActionsToolBar, AddButton } from '../../../components/grid/ActionsToolbar';
import { CardContent, Grid, Stack, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { useForm } from 'react-hook-form';
import { showConfirmDialog } from '../../../store/reducers/errors';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { FormAutoComplete } from '../../../components/form/FormAutoComplete';
import { CardModal } from '../../../components/card/CardModal';
import { openDrawer } from '../../../store/reducers/menu';
import {
  deleteReport,
  fetchReport, fetchReports,
  fetchReportsGroups,
  fetchReportsInputModes,
  fetchReportsOutputModes,
  updateReport
} from '../../../store/reducers/reporting';

export const ReportsConfiguration = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const reportsInputModes = useSelector((state) => state.reporting.reportsInputModes);
  const reportsOutputModes = useSelector((state) => state.reporting.reportsOutputModes);
  const reportsGroups = useSelector((state) => state.reporting.reportsGroups);
  const records = useSelector((state) => state.reporting.reports);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    if (isDataLoading(reportsGroups)) return;
    const payload = { body: {}, dispatch: dispatch };
    dispatch(fetchReportsGroups(payload)).then(() => {});
  }, [dispatch, reportsGroups]);

  useEffect(() => {
    if (dataLoaded) return;
    if (isDataLoading(reportsInputModes)) return;
    const payload = { body: {}, dispatch: dispatch };
    dispatch(fetchReportsInputModes(payload)).then(() => {});
  }, [dispatch, reportsInputModes]);

  useEffect(() => {
    if (dataLoaded) return;
    if (isDataLoading(reportsOutputModes)) return;
    const payload = { body: {}, dispatch: dispatch };
    dispatch(fetchReportsOutputModes(payload)).then(() => {});
  }, [dispatch, reportsOutputModes]);

  useEffect(() => {
    if (dataLoaded) return;
    if (isDataLoading(records)) return;
    dispatch(openDrawer({ drawerOpen: false }));
    const payload = { body: {}, dispatch: dispatch };
    dispatch(fetchReports(payload)).then(() => {
      setDataLoaded(true);
    });
  }, [dispatch, dataLoaded, records]);

  const onSubmit = (data) => {
    const record = { ...data };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateReport(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  const addAction = () => {
    const payload = { body: { mReportID: 0 }, dispatch: dispatch };
    dispatch(fetchReport(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      const payload = { body: { mReportID: selectedRow.mReportID }, dispatch: dispatch };
      dispatch(fetchReport(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const deleteAction = () => {
      const confirm = {
        title: 'Cancellazione report',
        message: 'Attenzione! Questa azione comporterà la cancellazione del report. Procedere?',
        handleHide: () => {},
        handleCancel: () => {},
        handleConfirm: () => {
          const payload = { body: { mReportID: selectedRow.mReportID }, dispatch: dispatch };
          dispatch(deleteReport(payload)).then(() => {
            setModalOpen(false);
            setDataLoaded(false);
          });
        }
      };
      dispatch(showConfirmDialog(confirm));
    };

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} />;
  };

  const [columns] = useState([
    { headerName: 'Codice', field: 'mReportCode' },
    { headerName: 'Nome', field: 'mLabel' },
    { headerName: 'Gruppo', field: 'mReportGroup' },
    { headerName: 'Input Type', field: 'mInputType.mInputDescription' },
    { headerName: 'Output Type', field: 'mOutputType.mOutputDescription' },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolBar' }
  ]);

  let outputFilterItems = [];
  if (!isDataNull(reportsGroups) && !isDataLoading(reportsGroups)) {
    reportsGroups.map((item) => {
      const idValue = item.mItemizedListValueID;
      const labelValue = item.mValue;
      outputFilterItems.push({ id: idValue, label: labelValue });
      return true;
    });
  }

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Reports</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pb: theme.spacing(2) }}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                <AddButton addAction={addAction} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            {dataLoaded && !isDataLoading(records) ? (
              <AgGridReact
                rowData={records}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio report'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          {!JSONIsEmpty(record) ? (
            <>
              <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="Codice"
                    control={control}
                    record={record}
                    register={register}
                    field="mReportCode"
                    required={true}
                    maxLength={100}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="Nome"
                    control={control}
                    record={record}
                    register={register}
                    field="mLabel"
                    required={true}
                    maxLength={100}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormAutoComplete
                    label="Gruppo"
                    control={control}
                    record={record}
                    register={register}
                    values={outputFilterItems}
                    field="mReportGroup"
                    joinField=""
                    idField="id"
                    labelField="label"
                    selectedValue="label"
                    required={true}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormAutoComplete
                    label="Tipo Input"
                    control={control}
                    record={record}
                    register={register}
                    values={reportsInputModes}
                    field="mInputType"
                    joinField="mInputID"
                    idField="mInputID"
                    labelField="mInputDescription"
                    selectedValue="id"
                    required={true}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormAutoComplete
                    label="Tipo Output"
                    control={control}
                    record={record}
                    register={register}
                    values={reportsOutputModes}
                    field="mOutputType"
                    joinField="mOutputID"
                    idField="mOutputID"
                    labelField="mOutputDescription"
                    selectedValue="id"
                    required={true}
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormTextBox
                    label="Query"
                    control={control}
                    record={record}
                    register={register}
                    field="mQuery"
                    required={true}
                    multiline={true}
                    lines={10}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <CircularWaiting />
          )}
        </CardContent>
      </CardModal>
    </>
  );
};
