import { Grid, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import {
  columnTypes,
  dateTimeColumnRenderer,
  defaultColDef,
  isDataLoading,
  isDataNull,
  JSONClone
} from '../../../../utils/common';
import { gridRowHeight, gridSmallSizeStyle } from '../../../../themes/Styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchLogs } from '../../../../store/reducers/controPanel';
import { PaginationToolBar } from '../../../../components/grid/PaginationToolBar';
import { CircularWaiting } from '../../../../components/waitings/CircularWaiting';

const default_page_state = {
  currentPage: 1,
  totalRows: 0,
  totalPages: 0,
  filter: {
    mFromDate: moment(),
    mToDate: moment()
  }
};

export const DbBackups = () => {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const records = useSelector((state) => state.controlPanel.logs);
  const [pagingState, setPagingState] = useState(JSONClone(default_page_state));

  const fetchData = async (bReset) => {
    const filter = {};
    filter.mRequestedPage = bReset ?? false ? 1 : pagingState.currentPage;
    const payload = { body: { ...filter }, dispatch: dispatch };
    dispatch(fetchLogs(payload)).then((res) => {
      const result = res.payload.result;
      const newPagingState = {
        ...pagingState,
        currentPage: result.responseDataCurrentPage,
        totalRows: result.responseDataRowsCount,
        totalPages: result.responseDataPagesCount,
        pageSize: result.responseDataPageSize
      };
      setPagingState(newPagingState);
    });
  };

  useEffect(() => {
    if (dataLoaded) return;
    if (isDataLoading(records)) return;
    fetchData(false).then(() => {
      setDataLoaded(true);
    });
  }, [dispatch, dataLoaded, records]);

  const setCurrentPage = (newPageNumber) => {
    const newPagingState = {
      ...pagingState,
      currentPage: newPageNumber
    };
    setPagingState(newPagingState);
    setDataLoaded(false);
  };

  const [columns] = useState([
    { headerName: 'Data', field: 'mLogDate', cellRenderer: dateTimeColumnRenderer },
    { headerName: 'Messaggio', field: 'mLogMessage' }
  ]);

  return (
    <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
      <Grid item xs={12}>
        <Typography variant="h4">Database Logs</Typography>
      </Grid>
      {dataLoaded && (
        <>
          <Grid item xs={12}>
            <PaginationToolBar
              currentPage={pagingState.currentPage}
              pagesCount={pagingState.totalPages}
              pageSize={pagingState.pageSize}
              setCurrentPage={setCurrentPage}
              rowsCount={pagingState.totalRows}
            />
          </Grid>
          <Grid item xs={12}>
            <div id="agReactGrid" className="ag-theme-alpine" style={gridSmallSizeStyle}>
              <AgGridReact
                rowData={records}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={false}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            </div>
          </Grid>
        </>
      )}
      {isDataLoading(records) || (isDataNull(records) && <CircularWaiting />)}
    </Grid>
  );
};
