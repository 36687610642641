import { Box, Grid, Link, Stack, styled, Typography } from '@mui/material';
import { CircularWaiting } from '../../../../components/waitings/CircularWaiting';
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { loggedClassNames } from '../../../../themes/Styles';
import { useForm } from 'react-hook-form';
import { ActionButton } from '../../../../components/buttons/ActionButton';
import LinearProgress from '@mui/material/LinearProgress';
import { ItemizedListAnswer } from './answers/ItemizedListAnswer';
import { BooleanAnswer } from './answers/BooleanAnswer';
import { NumericUpDownAnswer } from './answers/NumericUpDownAnswer';
import { TextAnswer } from './answers/TextAnswer';

const StyledQuestionBox = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  elevation: theme.spacing(3),
  // border: theme.spacing(0.2) + ' solid',
  // borderColor: theme.palette.grey['400'],
  width: '100%'
}));

const StyledQuestionDescription = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  minHeight: theme.spacing(0.5)
}));

const StyledQuestionHeader = styled(Box)(({ theme }) => ({
  ...theme.typography.h4,
  backgroundColor: theme.palette.primary.dark,
  textAlign: 'center',
  color: theme.palette.primary.contrastText,
  minHeight: theme.spacing(14),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderRadius: theme.spacing(1)
}));

const StyledQuestionBody = styled(Box)(({ theme }) => ({
  border: theme.spacing(0.2) + ' solid',
  borderTop: 'none',
  borderColor: theme.palette.grey['400'],
  marginTop: theme.spacing(-0.5),
  paddingBottom: theme.spacing(5),
  paddingTop: theme.spacing(5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1)
}));

const StyledQuestionFooter = styled(Box)(({ theme }) => ({
  ...theme.typography.h6,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  marginTop: theme.spacing(3)
}));

const FillAuditBlock = ({
  isWeb,
  isAuditLoading,
  isFirstBlock,
  isLastBlock,
  isCompleted,
  activeBlock,
  answersRecord,
  saveAnswerValue,
  handleResetAudit,
  handlePrevBlock,
  handleFinishAudit,
  handleNextBlock
}) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

  const questions = activeBlock ? activeBlock.mListOfQuestionTemplate ?? [] : [];
  const activeQuestion = questions[activeQuestionIndex];
  const isFirstQuestion = questions.length > 0 && activeQuestionIndex === 0;
  const isLastQuestion = questions.length > 0 && activeQuestionIndex === questions.length - 1;

  const answersForm = useForm({
    defaultValues: answersRecord,
    mode: 'all',
    // reValidateMode: 'onSubmit'
  });
  const {
    formState: { isValid },
    handleSubmit
  } = answersForm;

  console.log('redraw');

  const onReset = () => {
    handleResetAudit();
    setActiveQuestionIndex(0);
  };

  const onSubmit = (data) => {
    const fieldID = activeQuestion.mQuestionTemplateID;
    const value = data['q-' + fieldID];
    saveAnswerValue(activeQuestion, value);
  };

  const onSubmitNext = (data) => {
    console.log('onSubmitNext ');
    onSubmit(data);
    if (isLastQuestion) {
      handleNextBlock();
      setActiveQuestionIndex(0);
    } else {
      setActiveQuestionIndex((prevState) => prevState + 1);
    }
  };

  const onSubmitPrev = (data) => {
    console.log('onSubmitPrev ');
    onSubmit(data);
    if (isFirstQuestion) {
      handlePrevBlock();
      setActiveQuestionIndex(0);
    } else {
      setActiveQuestionIndex((prevState) => prevState - 1);
    }
  };

  const nextQuestion = () => {
    handleSubmit(onSubmitNext, onError)();
  };

  const prevQuestion = () => {
    handleSubmit(onSubmitPrev, onError)();
  };

  const onError = (errors, e) => {
    console.warn('onErrorAnswers ', errors, ' ', e);
  };

  const canReset = !isAuditLoading;
  const canGoToPrev = !isAuditLoading && !isCompleted && !(isFirstBlock && isFirstQuestion);
  const canGoToNext = !isAuditLoading && !isCompleted && !(isLastBlock && isLastQuestion);
  const canFinish = !isAuditLoading && !isCompleted && isLastBlock && isLastQuestion && isValid;

  const progress = ((activeQuestionIndex / questions.length) * 100).toFixed(2);

  const getAnswercomponent = () => {
    switch (activeQuestion.mAnswerTypeID) {
      case 1:
        return <ItemizedListAnswer question={activeQuestion} answersForm={answersForm} answersRecord={answersRecord} />;
      case 2:
        return <BooleanAnswer question={activeQuestion} answersForm={answersForm} answersRecord={answersRecord} />;
      case 3:
        return <NumericUpDownAnswer question={activeQuestion} answersForm={answersForm} answersRecord={answersRecord} />;
      case 4:
        return <TextAnswer question={activeQuestion} answersForm={answersForm} answersRecord={answersRecord} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <Grid container spacing={0} direction={'row'} display={'flex'} justifyContent={'center'} alignItems={'flex-start'}>
        {isAuditLoading && <CircularWaiting />}
        {!isAuditLoading && !isCompleted && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              {activeBlock && (
                <Typography variant={'h3'} textAlign={'center'}>
                  {activeBlock.mBlockTitle.toUpperCase()}{' '}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={loggedClasses.auditQuestions}>
              {activeQuestion && activeBlock && (
                <StyledQuestionBox key={'question-' + activeQuestionIndex}>
                  <StyledQuestionDescription>
                    {/*<Typography variant={'h5'}>{'Titolo domanda'}</Typography>*/}
                    {/*<Typography variant={'body2'}>{question.mTooltipText}</Typography>*/}
                  </StyledQuestionDescription>
                  <StyledQuestionHeader>
                    <Stack display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      {activeQuestion.mIsMandatory ? activeQuestion.mQuestionText + ' *' : activeQuestion.mQuestionText}
                    </Stack>
                  </StyledQuestionHeader>
                  <StyledQuestionBody>
                    <form>{getAnswercomponent()}</form>
                  </StyledQuestionBody>
                  <StyledQuestionFooter></StyledQuestionFooter>
                </StyledQuestionBox>
              )}
            </Grid>
            {canFinish && (
              <Grid item xs={12} sm={12} md={12} justifyContent={'center'} textAlign={'center'} sx={loggedClasses.auditFinish}>
                <Typography variant={'h3'}>Hai completato tutti i blocchi, clicca su Fine per salvare</Typography>
              </Grid>
            )}
            <Grid items xs={12} md={12} lg={12} sx={loggedClasses.auditControls}>
              <Grid container spacing={theme.spacing(1)} direction={'row'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Grid item xs={12} sm={12} md={2}>
                  <ActionButton
                    color="primary"
                    variant="contained"
                    action={onReset}
                    size="medium"
                    icon={'reset'}
                    iconSize="small"
                    tooltip={'Ricomincia'}
                    label={'Ricomincia'}
                    disabled={!canReset}
                    loading={false}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <ActionButton
                    color="primary"
                    variant="contained"
                    action={prevQuestion}
                    size="medium"
                    icon={'arrow-left'}
                    iconSize="small"
                    tooltip={'Domanda precedente'}
                    label={'Indietro'}
                    disabled={!canGoToPrev}
                    loading={false}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4} justifyContent={'center'} alignItems={'flex-start'} textAlign={'center'}>
                  <Typography variant={'h6'}>% completamento area funzionale</Typography>
                  <Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress variant="determinate" value={progress} />
                      </Box>
                    </Box>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <ActionButton
                    color="primary"
                    variant="contained"
                    action={nextQuestion}
                    size="medium"
                    icon={'arrow-right'}
                    iconSize="small"
                    tooltip={'Domanda successiva'}
                    label={'Avanti'}
                    disabled={!canGoToNext}
                    loading={false}
                    fullWidth={true}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <ActionButton
                    color="primary"
                    variant="contained"
                    action={handleFinishAudit}
                    size="medium"
                    icon={'arrow-right'}
                    iconSize="small"
                    tooltip={'Concludi audit'}
                    label={'Fine'}
                    disabled={!canFinish}
                    loading={false}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
              <Stack
                spacing={theme.spacing(1)}
                direction={'row'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={loggedClasses.auditControls}
              ></Stack>
            </Grid>
          </>
        )}

        {!isAuditLoading && isCompleted && (
          <Grid item xs={8} justifyContent={'center'} textAlign={'center'} sx={loggedClasses.auditQuestions}>
            <Typography variant={'h3'}>Questionario completato con successo.</Typography>
            {!isWeb && <Link href={'/Templates/Audits'}>Torna alla lista</Link>}
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default FillAuditBlock;
