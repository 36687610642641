import { Stack, useTheme } from '@mui/material';
import React from 'react';
import { FormCancelButton } from './FormCancelButton';
import { FormSubmitButton } from './FormSubmitButton';

export const FormActionBar = ({
  hasSubmit,
  submitLabel,
  submitTooltip,
  submitIcon,
  submitLoading,
  submitDisabled,
  submitExtraAction,
  hasCancel,
  cancelLabel,
  cancelTooltip,
  cancelAction,
  cancelIcon,
  cancelDisabled,
  ...otherProps
}) => {
  const theme = useTheme();

  let bHasSubmit = hasSubmit ?? true;
  let sSubmitLabel = submitLabel ?? 'Salva';
  let bSubmitDisabled = submitDisabled ?? false;
  let sSubmitIcon = submitIcon ?? 'save';
  let bHasCancel = hasCancel ?? true;
  let sCancelLabel = cancelLabel ?? 'Annulla';
  let bCancelDisabled = cancelDisabled ?? false;
  let sCancelIcon = cancelIcon ?? 'cancel';

  return (
    <Stack direction={'row'} spacing={theme.spacing(4)} sx={otherProps.sx}>
      {bHasSubmit ? (
        <FormSubmitButton
          label={sSubmitLabel}
          tooltip={submitTooltip}
          icon={sSubmitIcon}
          disabled={bSubmitDisabled}
          extraAction={submitExtraAction}
          loading={submitLoading}
        />
      ) : (
        <></>
      )}
      {bHasCancel ? (
        <FormCancelButton
          label={sCancelLabel}
          tooltip={cancelTooltip}
          icon={sCancelIcon}
          action={cancelAction}
          disabled={bCancelDisabled}
        />
      ) : (
        <></>
      )}
    </Stack>
  );
};
