import React, { useEffect, useState } from 'react';
import { gridMediumSizeStyle, gridRowHeight } from '../../../../themes/Styles';
import { useDispatch } from 'react-redux';
import { columnTypes, defaultColDef, isDataLoading } from '../../../../utils/common';
import { clearReport, fetchReports } from '../../../../store/reducers/reporting';
import { openDrawer } from '../../../../store/reducers/menu';
import { ActionsToolBar } from '../../../../components/grid/ActionsToolbar';
import { Grid, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { CircularWaiting } from '../../../../components/waitings/CircularWaiting';
import { showConfirmDialog } from '../../../../store/reducers/errors';

export const Anomalies = () => {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [records, setRecords] = useState(false);

  useEffect(() => {
    if (dataLoaded) return;
    if (isDataLoading(records)) return;
    dispatch(openDrawer({ drawerOpen: false }));
    const payload = { body: { mReportGroup: 'Incongruenze' }, dispatch: dispatch };
    dispatch(fetchReports(payload)).then((res) => {
      setRecords(res.payload.result.responseData);
      setDataLoaded(true);
    });
  }, [dispatch, dataLoaded, records]);

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const clearAction = () => {
      const confirm = {
        title: 'Bonifica',
        message: 'Attenzione! Questo effettuerà una bonifica dei dati esistenti. Procedere?',
        handleHide: () => {},
        handleCancel: () => {},
        handleConfirm: () => {
          const payload = { body: { mReportID: selectedRow.mReportID }, dispatch: dispatch };
          dispatch(clearReport(payload)).then(() => {
            setDataLoaded(false);
          });
        }
      };
      dispatch(showConfirmDialog(confirm));
    };

    const customActions = [
      {
        action: clearAction,
        disabled: false,
        icon: 'play',
        tooltip: 'Bonifica'
      }
    ];

    return <ActionsToolBar hasEdit={false} hasDelete={false} customActions={customActions} />;
  };

  const [columns] = useState([
    { headerName: 'Codice', field: 'mReportCode' },
    { headerName: 'Nome', field: 'mLabel' },
    { headerName: 'Totale' , field: 'mScalarValue'},
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolBar' }
  ]);

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Incongruenze</Typography>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridMediumSizeStyle}>
            {dataLoaded && !isDataLoading(records) ? (
              <AgGridReact
                rowData={records}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};
