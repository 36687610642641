import {
  Avatar,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme
} from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { columnTypes, defaultColDef, isDataLoading, isDataNull } from '../../../utils/common';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { ActionsToolBar, AddButton } from '../../../components/grid/ActionsToolbar';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { CardModal } from '../../../components/layout/CardModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteItemizedListValue,
  fetchItemizedList,
  fetchItemizedLists,
  fetchItemizedListValues,
  updateItemizedList,
  updateItemizedListValue
} from '../../../store/reducers/registries';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { openDrawer } from '../../../store/reducers/menu';
import { ActionButton } from '../../../components/buttons/ActionButton';

const empty_list_item = {
  id: 0,
  value: ''
};

const ItemizedListEditor = ({ itemizedListID }) => {
  const theme = useTheme();

  const dispatch = useDispatch();

  const [dataLoaded, setDataLoaded] = useState(false);
  const [values, setValues] = useState(null);
  const [currentItem, setCurrentItem] = useState(empty_list_item);

  const textRef = useRef(null);

  useEffect(() => {
    if (dataLoaded) return;
    if (!isDataLoading(values)) {
      const payload = { body: { mItemizedListID: itemizedListID }, dispatch: dispatch };
      dispatch(fetchItemizedListValues(payload)).then((res) => {
        setValues(res.payload.result.responseData);
        setDataLoaded(true);
        setCurrentItem(empty_list_item);
      });
    }
  }, [dataLoaded, dispatch, itemizedListID, values]);

  const onValueToAddChange = (e) => {
    const newCurrItem = { ...currentItem, value: e.target.value };
    setCurrentItem(newCurrItem);
  };

  const cancelClicked = () => {
    setCurrentItem(empty_list_item);
    if (!textRef.current) return;
    textRef.current.focus();
  };

  const saveClicked = () => {
    const record = {
      mItemizedList: { mItemizedListID: itemizedListID },
      mItemizedListValueID: currentItem.id,
      mValue: currentItem.value
    };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateItemizedListValue(payload)).then(() => {
      setDataLoaded(false);
    });
  };

  const ExtraToolBar = (item) => {
    const editAction = () => {
      const selectedItem = { id: item.mItemizedListValueID, value: item.mValue };
      setCurrentItem(selectedItem);
    };

    const deleteAction = () => {
      const record = { mItemizedListValueID: item.mItemizedListValueID };
      const payload = { body: { ...record }, dispatch: dispatch };
      dispatch(deleteItemizedListValue(payload)).then(() => {
        setDataLoaded(false);
      });
    };

    return <ActionsToolBar hasDelete deleteAction={deleteAction} hasEdit editAction={editAction} />;
  };

  if (isDataNull(values) || isDataLoading(values)) return <CircularWaiting />;

  return (
    <Grid container spacing={theme.spacing(2)} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
      <Grid item xs={12} md={6}>
        <TextField
          variant="filled"
          label={'Valore'}
          inputRef={textRef}
          value={currentItem.value}
          onChange={onValueToAddChange}
          autoFocus
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack direction={'row'} spacing={theme.spacing(4)}>
          <ActionButton
            color="info"
            variant="contained"
            action={saveClicked}
            size="small"
            icon="save"
            iconSize="medium"
            tooltip={currentItem.id === 0 ? 'Aggiungi' : 'Salva'}
            label={currentItem.id === 0 ? 'Aggiungi' : 'Salva'}
            disabled={currentItem.value === ''}
          />
          <ActionButton
            color="primary"
            variant="contained"
            action={cancelClicked}
            size="small"
            icon="reset"
            iconSize="medium"
            tooltip="Annulla"
            label="Annulla"
            disabled={currentItem.value === ''}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <List dense={true}>
          {values.map((item, index) => {
            return (
              <ListItem secondaryAction={ExtraToolBar(item)} key={index}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: theme.palette.secondary.main }}>{index + 1}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.mValue} />
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

const ItemizedLists = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  const domains = useSelector((state) => state.registries.domains);
  const itemizedLists = useSelector((state) => state.registries.itemizedLists);

  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [extraRecordId, setExtraRecordId] = useState(-1);
  const [extraModalOpen, setExtraModalOpen] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    if (!isDataLoading(itemizedLists)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchItemizedLists(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, domains, itemizedLists]);

  //dettaglio

  const onSubmit = (data) => {
    const record = { ...data };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateItemizedList(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const addAction = () => {
    const payload = { body: { mItemizedListID: 0 }, dispatch: dispatch };
    dispatch(fetchItemizedList(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      const payload = { body: { mItemizedListID: selectedRow.mItemizedListID }, dispatch: dispatch };
      dispatch(fetchItemizedList(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const deleteAction = () => {
      // const payload = { body: { ...selectedRow }, dispatch: dispatch };
      // dispatch(deleteItemizedList(payload)).then(() => {
      //   setDataLoaded(false);
      // });
      alert('TO BE IMPLEMENTED');
    };

    const linkedValuesAction = () => {
      setExtraRecordId(selectedRow.mItemizedListID);
      setExtraModalOpen(true);
    };

    const customActions = [
      {
        action: linkedValuesAction,
        disabled: false,
        icon: 'list',
        tooltip: 'Valori della lista'
      }
    ];

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} customActions={customActions} />;
  };

  const [columns] = useState([
    { headerName: 'ID', field: 'mItemizedListID' },
    { headerName: 'Descrizione', field: 'mSQLName' },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolBar' }
  ]);

  const onExtraClose = () => {
    setExtraModalOpen(false);
    setExtraRecordId(-1);
  };

  if (isDataNull(itemizedLists) || isDataLoading(itemizedLists)) return <CircularWaiting />;

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Liste</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pb: theme.spacing(2) }}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                <AddButton addAction={addAction} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            {dataLoaded && !isDataLoading(itemizedLists) ? (
              <AgGridReact
                rowData={itemizedLists}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio lista'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12}>
              <FormTextBox
                classes={loggedClasses}
                label="Descrizione"
                control={control}
                record={record}
                register={register}
                field="mSQLName"
                required={true}
                maxLength={50}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
      <CardModal modalOpen={extraModalOpen} onClose={onExtraClose} title={'Valori della lista'} size={'small'}>
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12}>
              <ItemizedListEditor itemizedListID={extraRecordId} />
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
    </>
  );
};

export default ItemizedLists;
