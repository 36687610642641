import { FormControl } from '@mui/material';
import React from 'react';
import { ActionButton } from '../buttons/ActionButton';

export const FormSubmitButton = ({ label, tooltip, disabled, icon, extraAction, loading, fullWidth, sx, ...props }) => {
  let isDisabled = disabled ?? false;
  let sIcon = icon ?? 'save';
  let sTooltip = tooltip ? tooltip : label;

  return (
    <FormControl fullWidth={fullWidth}>
      <ActionButton
        color="primary"
        variant="contained"
        action={extraAction}
        size="medium"
        icon={sIcon}
        iconSize="small"
        tooltip={sTooltip}
        label={label}
        type="submit"
        disabled={isDisabled}
        loading={loading}
        fullWidth={fullWidth ?? false}
        sx={sx}
        {...props}
      />
    </FormControl>
  );
};
