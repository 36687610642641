import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { commonClassNames } from '../../themes/Styles';

export const CustomTabPanel = (props) => {
  const theme = useTheme();
  const commonClasses = commonClassNames(theme);

  const { children, value, index, sx, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={sx}
      {...other}
    >
      {value === index && <Box sx={commonClasses.tabPanelBox}>{children}</Box>}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};
