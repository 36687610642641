import { Box, Chip, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import Chart from 'react-apexcharts';
import GaugeChart from 'react-gauge-chart';
import { loggedClassNames } from '../../themes/Styles';
import { CircularWaiting } from '../waitings/CircularWaiting';
import { isDataLoading, isDataNull } from '../../utils/common';
import { maxAnswerValue } from '../../store/reducers/templates';
import { StyledChartHeader } from './SyledChartHeader';

const AuditChart = ({ chartObject }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const customColors = [
    theme.palette.primary.dark,
    theme.palette.error.dark,
    theme.palette.secondary.main,
    theme.palette.success.dark,
    theme.palette.warning.main,
    theme.palette.text.secondary
  ];

  let dataSeries = [];
  let dataCategories = [];
  let chartData = chartObject.mChartData ?? [];
  let chartType = chartObject.mChartOutputType ?? '';
  let plotOptions = {};
  let dataLabelsOptions = {};
  let chartOptions = {};
  let xAxisOptions = {};
  let yAxisOptions = {};
  let markersOptions = {};
  let tooltipOptions = {};
  let gridOptions = {};
  let sChartType = '';
  let divStyle = {};
  const chartHeight =
    chartObject.columns === 3
      ? 400
      : chartObject.columns === 4
      ? 400
      : chartObject.columns === 6
      ? 250
      : chartObject.columns === 8
      ? 450
      : chartObject.columns === 9
      ? 450
      : chartObject.columns === 12
      ? 450
      : 100;

  let chartBodyStyle = {
    height: chartHeight + 50,
    ...loggedClasses.chartBodyStyle
  };

  const titleStyle = {
    fontSize: theme.typography.fontSize + 8,
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily
  };

  dataLabelsOptions = {
    style: {
      fontSize: theme.typography.fontSize - 5,
      fontWeight: 'bold',
      fontFamily: theme.typography.fontFamily,
      minWidth: 50
    },
    textAnchor: 'end',
    background: {
      enabled: true,
      foreColor: theme.palette.text.primary,
      borderRadius: 2,
      padding: 4,
      opacity: 0.9,
      borderWidth: 1,
      borderColor: theme.palette.primary
    }
  };

  chartOptions = {
    fontSize: theme.typography.fontSize,
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily
  };

  gridOptions = {
    row: {
      colors: [theme.palette.grey['100'], theme.palette.grey['300']], // takes an array which will be repeated on columns
      opacity: 0.2
    }
  };

  const dataValid = !isDataNull(chartData) && !isDataLoading(chartData);

  const arrBlocks = chartData ?? [];

  const blocksCount = arrBlocks.length;

  const completedValue = arrBlocks.map((v) => v.mValue).reduce((accumulator, item) => accumulator + item, 0);
  const totalValue = maxAnswerValue * blocksCount;
  chartData = chartData.map((v) => {
    return {
      ...v,
      mValue: parseFloat(v.mValue.toFixed(2)),
      mPercValue: parseFloat(((v.mValue / totalValue) * 100).toFixed(2))
    };
  });
  const avgValue = blocksCount > 0 ? completedValue / blocksCount : 0;
  const pIndex = totalValue > 0 ? completedValue / totalValue : 0;
  const pIndexPerc = pIndex * 100;
  const pIndexLabels = [
    { label: 'Per niente conforme', min: 0, max: 25 },
    { label: 'Poco conforme', min: 26, max: 50 },
    { label: 'Parzialmente conforme', min: 51, max: 75 },
    { label: 'Pienamente conforme', min: 76, max: 100 }
  ];
  const pIndexLabel = pIndexLabels.find((v) => pIndexPerc >= v.min && pIndexPerc <= v.max).label;
  const gaugeColors = [theme.palette.error.main, theme.palette.warning.main, theme.palette.success.light, theme.palette.success.main];
  const arcsLength = [0.25, 0.25, 0.25, 0.25];

  if (dataValid && chartType === 'horizontal-bar') {
    const categories = chartData.map((v) => v.mTitle);
    dataCategories = [...categories];
    chartData.map((v) => {
      dataSeries.push({ name: v.mTitle, data: [v.mValue] });
    });

    sChartType = 'bar';

    chartOptions = {
      ...chartOptions,
      type: 'bar'
    };

    plotOptions = {
      bar: {
        barHeight: '100%',
        horizontal: true,
        dataLabels: {
          position: 'bottom',
          style: { ...titleStyle }
        }
      }
    };

    dataLabelsOptions = {
      ...dataLabelsOptions,
      offsetX: 20,
      formatter: function (val) {
        return val.toFixed(2);
      }
    };

    xAxisOptions = {
      ...xAxisOptions,
      labels: {
        formatter: function (val) {
          return val.toFixed(2) + '%';
        }
      }
    };

    yAxisOptions = {
      ...yAxisOptions,
      max: maxAnswerValue
    };

    divStyle = {
      height: chartHeight,
      width: '100%'
    };
  } else if (dataValid && chartType === 'stack') {
    dataCategories.push('');
    chartData.map((v) => {
      dataSeries.push({ name: v.mTitle, data: [v.mPercValue] });
    });

    sChartType = 'bar';

    chartOptions = {
      ...chartOptions,
      type: 'bar',
      stacked: true
    };

    plotOptions = {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
          formatter: function (val) {
            return val.toFixed(2);
          },
          style: {
            ...titleStyle
          }
        }
      }
    };

    xAxisOptions = {
      ...xAxisOptions,
      max: 100,
      labels: {
        formatter: function (val) {
          return val.toFixed(2) + '%';
        }
      }
    };

    yAxisOptions = {
      ...yAxisOptions,
      // max: 100,
      // labels: {
      //   formatter: function (val) {
      //     return val.toFixed(2) + '%';
      //   }
      // }
    };

    divStyle = {
      height: chartHeight,
      width: '100%'
    };
  } else if (dataValid && chartType === 'radar') {
    let values = chartData.map((v) => v.mValue);
    let categories = chartData.map((v) => v.mTitle);

    for (let iFill = chartData.length - 1; iFill < 5; iFill++) {
      values = [...values, 0];
      categories = [...categories, ''];
    }

    dataSeries.push({ name: chartObject.mChartDescription, data: values });
    dataCategories = [...categories];

    sChartType = 'radar';

    chartOptions = {
      ...chartOptions,
      type: 'radar'
    };

    plotOptions = {};

    yAxisOptions = {
      tickAmount: 4,
      min: 0,
      max: maxAnswerValue,
      decimalsInFloat: 0
    };

    tooltipOptions = {
      enabled: false
    };

    divStyle = {
      height: chartHeight,
      width: '100%'
    };
  } else if (dataValid && chartType === 'gauge') {
    dataSeries.push({
      name: chartObject.mChartDescription,
      data: pIndex
    });

    sChartType = 'gauge';

    markersOptions = {
      size: 12,
      colors: [theme.palette.grey['50']],
      strokeColor: theme.palette.primary.main,
      strokeWidth: 2
    };

    divStyle = {
      height: chartHeight,
      width: '100%'
    };
  } else if (dataValid && chartType === 'box') {
    dataSeries.push({
      name: 'Punteggio ottenuto %',
      data: [avgValue],
      color: 'info',
      decimals: 2
    });

    dataSeries.push({
      name: 'Punteggio massimo %',
      data: [maxAnswerValue],
      color: 'error',
      decimals: 2
    });

    dataSeries.push({
      name: 'Grado di conformità',
      data: [pIndexLabel],
      color: 'secondary'
    });

    dataSeries.push({
      name: 'Numero di aree soggette ad audit',
      data: [blocksCount],
      color: 'primary'
    });

    sChartType = 'box';

    divStyle = {
      height: chartHeight,
      width: '100%'
    };
  }

  const apexChartOptions = {
    chart: { ...chartOptions },
    plotOptions: { ...plotOptions },
    colors: customColors,
    markers: { ...markersOptions },
    dataLabels: { ...dataLabelsOptions },
    yaxis: { ...yAxisOptions },
    xaxis: { ...xAxisOptions, categories: dataCategories },
    grid: { ...gridOptions },
    tooltip: { ...tooltipOptions }
  };

  if (isDataNull(chartData) || isDataLoading(chartData)) return <CircularWaiting />;

  return (
    <Box justifyContent={'center'} sx={loggedClasses.chartContainerStyle}>
      <StyledChartHeader>{chartObject.mChartDescription}</StyledChartHeader>
      <Box sx={chartBodyStyle}>
        {sChartType === 'bar' || sChartType === 'radar' ? (
          <Chart type={sChartType} series={dataSeries} options={apexChartOptions} {...divStyle} />
        ) : sChartType === 'gauge' ? (
          <Stack key={`gaugeStackInner`} id={`gaugeStackInner`} direction={'row'} spacing={theme.spacing(2)}>
            {dataSeries.map((ds, index) => {
              return (
                <GaugeChart
                  key={`gaugeChart-${index}`}
                  id={`gaugeChart-${index}`}
                  nrOfLevels={20}
                  colors={gaugeColors}
                  arcsLength={arcsLength}
                  needleColor={theme.palette.primary.dark}
                  needleBaseColor={theme.palette.primary.dark}
                  percent={ds.data}
                  style={divStyle}
                  cornerRadius={2}
                  textColor={theme.palette.common.black}
                />
              );
            })}
          </Stack>
        ) : sChartType === 'box' ? (
          <>
            {dataSeries.map((ds) => {
              const label = ds.decimals ? ds.data[0].toFixed(ds.decimals ?? 2) : ds.data[0];
              return (
                <>
                  <Typography sx={titleStyle}>{ds.name}</Typography>
                  <Chip color={ds.color ?? 'primary'} label={label} sx={titleStyle} />
                </>
              );
            })}
          </>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default AuditChart;
