import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { loggedClassNames } from '../../../themes/Styles';
import { Paper, Tab, Tabs } from '@mui/material';
import { CustomTabPanel } from '../../../components/tab/CustomTabPanel';
import { ConnectedUsers } from './components/ConnectedUsers';
import { openDrawer } from '../../../store/reducers/menu';
import { useDispatch } from 'react-redux';
import { DbBackups } from './components/DbBackups';
import { Anomalies } from './components/Anomalies';
import { SystemMessages } from './components/SystemMessages';
import { SystemStats } from './components/SystemStats';

export const ControlPanel = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const loggedClasses = loggedClassNames(theme);
  const [selectedTab, setSelectedTab] = useState(0);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    setDataLoaded(true);
  }, [dispatch, dataLoaded]);

  const tabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const a11yProps = (index) => {
    return {
      id: `control-panel-tab-${index}`,
      'aria-controls': `control-panel-tabpanel-${index}`
    };
  };

  return (
    <Paper variant={'oulined'} sx={loggedClasses.tabContainer}>
      <Tabs
        value={selectedTab}
        variant={'fullWidth'}
        aria-label="basic tabs example"
        onChange={tabChange}
        sx={loggedClasses.tabButtonsContainer}
      >
        <Tab index={0} label={'DB Backup'} {...a11yProps(0)} sx={loggedClasses.tabButton} />;
        <Tab index={1} label={'Incongruenze'} {...a11yProps(1)} sx={loggedClasses.tabButton} />;
        <Tab index={2} label={'Messaggistica'} {...a11yProps(2)} sx={loggedClasses.tabButton} />;
        <Tab index={3} label={'Sessioni'} {...a11yProps(3)} sx={loggedClasses.tabButton} />;
        <Tab index={4} label={'Statistiche'} {...a11yProps(4)} sx={loggedClasses.tabButton} />;
      </Tabs>
      <>
        <CustomTabPanel value={selectedTab} index={0} sx={loggedClasses.tabPanel}>
          <DbBackups />
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={1} sx={loggedClasses.tabPanel}>
          <Anomalies />
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={2} sx={loggedClasses.tabPanel}>
          <SystemMessages />
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={3} sx={loggedClasses.tabPanel}>
          <ConnectedUsers />
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={4} sx={loggedClasses.tabPanel}>
          <SystemStats />
        </CustomTabPanel>
      </>
    </Paper>
  );
};
