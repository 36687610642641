import React from 'react';
import { useSelector } from 'react-redux';
import FillAudit from './components/FillAudit';

const FillAuditByGrid = () => {
  const auditToOpen = useSelector((state) => state.templates.auditToOpen ?? {});
  const auditID = auditToOpen.mAudit.mAuditID;

  return <FillAudit auditID={auditID} />;
};

export default FillAuditByGrid;
