import { CardContent, Grid, Stack, Typography, useTheme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  activeRendererWithBadge,
  booleanMatcher,
  columnTypes,
  CUSTOMER_ADMIN,
  defaultColDef,
  isDataLoading,
  isDataNull,
  SCHEMA_ADMIN,
  SYSTEM_ADMIN
} from '../../../utils/common';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { ActionsToolBar, AddButton } from '../../../components/grid/ActionsToolbar';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { CardModal } from '../../../components/layout/CardModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteUser,
  fetchCompanies,
  fetchDepartments,
  fetchProfiles,
  fetchSites,
  fetchUser,
  fetchUsers,
  updateUser
} from '../../../store/reducers/registries';
import { FormAutoComplete } from '../../../components/form/FormAutoComplete';
import { openDrawer } from '../../../store/reducers/menu';
import { showConfirmDialog } from '../../../store/reducers/errors';

const Users = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  const companies = useSelector((state) => state.registries.companies);
  const profiles = useSelector((state) => state.registries.profiles);
  const users = useSelector((state) => state.registries.users);
  const departments = useSelector((state) => state.registries.departments);
  const sites = useSelector((state) => state.registries.sites);

  const authStatus = useSelector((state) => state.auth.authStatus) ?? {};
  const loggedUser = authStatus.loggedUser ?? {};
  const loggedProfile = (loggedUser.mProfile ?? {}).mSQLName ?? '';

  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    if (!isDataLoading(profiles)) {
      const payloadProfiles = { body: {}, dispatch: dispatch };
      dispatch(fetchProfiles(payloadProfiles)).then(() => {
        // console.log('res ', res);
      });
    }
    if (!isDataLoading(companies)) {
      const payloadCompanies = { body: { mType: 'COMPANY' }, dispatch: dispatch };
      dispatch(fetchCompanies(payloadCompanies)).then(() => {
        // console.log('res ', res);
      });
    }
    if (!isDataLoading(departments)) {
      const payloadDepartments = { body: {}, dispatch: dispatch };
      dispatch(fetchDepartments(payloadDepartments)).then(() => {
        // console.log('res ', res);
      });
    }
    if (!isDataLoading(sites)) {
      const payloadSites = { body: {}, dispatch: dispatch };
      dispatch(fetchSites(payloadSites)).then(() => {
        // console.log('res ', res);
      });
    }
    if (!isDataLoading(users)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchUsers(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, users, profiles, companies]);

  //dettaglio

  const onSubmit = (data) => {
    const record = { ...data };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateUser(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const addAction = () => {
    const payload = { body: { mUserID: 0 }, dispatch: dispatch };
    dispatch(fetchUser(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      const payload = { body: { mUserID: selectedRow.mUserID }, dispatch: dispatch };
      dispatch(fetchUser(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const deleteAction = () => {
      const confirm = {
        title: 'Cancellazione azienda',
        message: 'Attenzione! Questa azione comporterà la cancellazione di tutti gli audit di questa azienda. Procedere?',
        handleHide: () => {},
        handleCancel: () => {},
        handleConfirm: () => {
          const payload = { body: { ...selectedRow }, dispatch: dispatch };
          dispatch(deleteUser(payload)).then(() => {
            setDataLoaded(false);
          });
        }
      };
      dispatch(showConfirmDialog(confirm));
    };

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} />;
  };

  let initColumns = [
    { headerName: 'Username', field: 'mUserName' },
    { headerName: 'Nome', field: 'mFirstName' },
    { headerName: 'Cognome', field: 'mLastName' }
  ];

  if (loggedProfile === SYSTEM_ADMIN) {
    initColumns.push({ headerName: 'Dipendente di', field: 'mCompany.mCompanyName' });
  }

  initColumns = [
    ...initColumns,
    { headerName: 'Dipartimento', field: 'mDepartment.mDepartmentName' },
    { headerName: 'Sede', field: 'mWorkSite.mSiteName' },
    { headerName: 'Profilo', field: 'mProfile.mProfileName' },
    {
      headerName: 'Attivo',
      field: 'mLoginStatus',
      cellRenderer: activeRendererWithBadge,
      filterParams: { textMatcher: booleanMatcher }
    },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolBar' }
  ];

  const [columns] = useState(initColumns);

  if (isDataNull(companies) || isDataLoading(companies)) return <CircularWaiting />;
  if (isDataNull(profiles) || isDataLoading(profiles)) return <CircularWaiting />;
  if (isDataNull(users) || isDataLoading(users)) return <CircularWaiting />;

  let activeProfiles = loggedProfile === CUSTOMER_ADMIN ? profiles.filter((prof) => prof.mSQLName === SCHEMA_ADMIN) : profiles;

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Utenti</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pb: theme.spacing(2) }}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                <AddButton addAction={addAction} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            {dataLoaded && !isDataLoading(users) ? (
              <AgGridReact
                rowData={users}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio utente'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container spacing={theme.spacing(2)} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12} lg={4}>
              <FormTextBox
                label="Username"
                control={control}
                record={record}
                register={register}
                field="mUserName"
                required={true}
                maxLength={100}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormTextBox
                label="Nome"
                control={control}
                record={record}
                register={register}
                field="mFirstName"
                required={true}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormTextBox
                label="Cognome"
                control={control}
                record={record}
                register={register}
                field="mLastName"
                required={true}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormTextBox
                label="Cellulare"
                control={control}
                record={record}
                register={register}
                field="mMobilePhone"
                required={true}
                maxLength={50}
              />
            </Grid>
            {loggedProfile === SYSTEM_ADMIN && (
              <Grid item xs={12} lg={4}>
                <FormAutoComplete
                  label="Dipendente della società"
                  control={control}
                  record={record}
                  register={register}
                  field="mCompany"
                  joinField="mCompanyID"
                  values={companies}
                  idField="mCompanyID"
                  labelField="mCompanyName"
                  selectedValue="id"
                  required={false}
                />
              </Grid>
            )}
            <Grid item xs={12} lg={4}>
              <FormAutoComplete
                label="Dipartimento"
                control={control}
                record={record}
                register={register}
                field="mDepartment"
                joinField="mDepartmentID"
                values={departments}
                idField="mDepartmentID"
                labelField="mDepartmentName"
                selectedValue="id"
                required={false}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <FormAutoComplete
                label="Sede"
                control={control}
                record={record}
                register={register}
                field="mWorkSite"
                joinField="mSiteID"
                values={sites}
                idField="mSiteID"
                labelField="mSiteName"
                selectedValue="id"
                required={false}
              />
            </Grid>
            {record && record.mUserID === 0 ? (
              <Grid item xs={12} lg={8}>
                <FormAutoComplete
                  label="Profilo"
                  control={control}
                  record={record}
                  register={register}
                  field="mProfile"
                  joinField="mProfileID"
                  values={activeProfiles}
                  idField="mProfileID"
                  labelField="mProfileName"
                  selectedValue="id"
                  required={true}
                />
              </Grid>
            ) : (
              <Grid item xs={12} lg={8}>
                <FormAutoComplete
                  label="Profilo"
                  control={control}
                  record={record}
                  register={register}
                  field="mProfile"
                  joinField="mProfileID"
                  values={profiles}
                  idField="mProfileID"
                  labelField="mProfileName"
                  selectedValue="id"
                  required={true}
                />
              </Grid>
            )}

            {/*{isAdmin && (*/}
            {/*  <Grid item xs={12} lg={4}>*/}
            {/*    <FormAutoComplete*/}
            {/*      label="Stato"*/}
            {/*      control={control}*/}
            {/*      record={record}*/}
            {/*      register={register}*/}
            {/*      values={loginStatusItems}*/}
            {/*      field="mLoginStatus"*/}
            {/*      joinField=""*/}
            {/*      idField="id"*/}
            {/*      labelField="label"*/}
            {/*      selectedValue="label"*/}
            {/*      required={true}*/}
            {/*      disabled={false}*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*)}*/}
          </Grid>
        </CardContent>
      </CardModal>
    </>
  );
};

export default Users;
