import { Autocomplete, Box, createFilterOptions, FormControl, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { IconLibraryItem, iconsLibrary } from '../icons/IconLibraryItem';

export const FormIconPicker = ({ itemsCount, focused, ...props }) => {
  const getOptions = () => {
    let options = {};
    if (props.required !== null) {
      options.required = {};
      options.required.value = props.required;
      if (props.required === true) {
        options.required.message = 'Campo richiesto';
      }
    }
    return options;
  };

  const isDisabled = () => {
    if (props.disabled) {
      return props.disabled;
    } else {
      return false;
    }
  };

  let icons = iconsLibrary;

  let items = [];
  icons.map((item) => {
    items.push({ id: item.name, label: item.name });
    return true;
  });

  const normalize = (value) => {
    return (value ?? '').toString().trim().toUpperCase();
  };

  const valuesAreEqual = (value1, value2) => {
    return normalize(value1) === normalize(value2);
  };

  const getSelectedValue = (value) => {
    if (!value) return value;
    if ((props.joinField ?? '') !== '') {
      let foundItem = items.find((item) => valuesAreEqual(item['id'], value[props.joinField]));
      if (!foundItem) return null;
      return foundItem.label.toUpperCase();
    } else {
      let foundItem = items.find((item) => valuesAreEqual(item['id'], value));
      if (!foundItem) return null;
      return foundItem.label.toUpperCase();
    }
  };

  const defaultFilterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => normalize(option.label)
  });

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state).slice(0, itemsCount);
  };

  return (
    <FormControl fullWidth>
      <Controller
        name={props.field}
        control={props.control}
        render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
          <>
            <Autocomplete
              name={`icon-picker-${name}`}
              key={`icon-picker-${name}`}
              disablePortal
              options={items}
              value={getSelectedValue(value) || null}
              filterOptions={filterOptions}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => {
                if (!value) return false;
                return (props.joinField ?? '') !== ''
                  ? valuesAreEqual(value[props.joinField] === option['id'])
                  : valuesAreEqual(value === option['id']);
              }}
              onChange={(event, item) => {
                let newValue = null;
                if (item && (props.joinField ?? '') !== '') {
                  newValue = value ?? {};
                  newValue[props.joinField] = item ? item['id'] : null;
                } else {
                  newValue = item ? item['id'] : null;
                }
                onChange(newValue);
              }}
              sx={{ ...props.sx }}
              autoHighlight
              autoSelect
              renderOption={(renderProps, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...renderProps}>
                  <IconLibraryItem name={option.id} iconSize={'small'} /> &nbsp;
                  {option.label.toUpperCase()}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  label={props.label}
                  inputRef={ref}
                  variant="outlined"
                  focused={focused}
                  {...params}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
              disabled={isDisabled()}
            />
          </>
        )}
        {...props.register(props.field, getOptions())}
      />
    </FormControl>
  );
};
