import { FormTextBox } from '../../../../../components/form/FormTextBox';
import React from 'react';

export const NumericUpDownAnswer = ({ question, answersForm, answersRecord }) => {
  return (
    <FormTextBox
      label={''}
      control={answersForm.control}
      record={answersRecord}
      register={answersForm.register}
      field={'q-' + question.mQuestionTemplateID}
      disabled={false}
      maxLength={100}
      pattern={'number'}
      required={question.mIsMandatory ?? false}
      fullWidth
      focused={true}
    />
  );
};
