import React from 'react';
import { Chip, Divider, Stack, Typography, useTheme } from '@mui/material';
import { loggedClassNames } from '../../themes/Styles';
import { ActionButton } from '../buttons/ActionButton';

export const PaginationToolBar = ({ pageSize, pagesCount, currentPage, setCurrentPage, children, rowsCount }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const decreaseValue = () => {
    let newValue = parseInt(currentPage) - 1;
    if (newValue < 1) {
      return;
    }
    setCurrentPage(newValue);
  };

  const increaseValue = () => {
    let newValue = parseInt(currentPage) + 1;
    if (newValue > parseInt(pagesCount)) {
      return;
    }
    setCurrentPage(newValue);
  };

  const prevDisabled = () => {
    return currentPage === 1;
  };

  const nextDisabled = () => {
    return currentPage === parseInt(pagesCount);
  };

  const sx = loggedClasses.paginationToolbarHeightSx;

  return (
    <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={loggedClasses.paginationToolbar}>
      <>{children}</>
      <ActionButton
        color="primary"
        variant="contained"
        action={decreaseValue}
        size="small"
        icon="prev"
        iconSize="small"
        tooltip="Precedente"
        label="Precedente"
        sx={sx}
        disabled={prevDisabled()}
      />
      <ActionButton
        color="primary"
        variant="contained"
        action={increaseValue}
        size="small"
        icon="next"
        iconSize="small"
        tooltip="Successiva"
        label="Successiva"
        sx={sx}
        disabled={nextDisabled()}
      />
      <Stack direction={'column'} spacing={theme.spacing(1)} sx={loggedClasses.paginationToolbar}>
        <Typography variant={'caption'}>
          Pagina {currentPage} di {pagesCount} ({pageSize} righe per pagina)
        </Typography>
        <Divider />
        <Typography variant={'caption'}>
          Righe totali <Chip size={'small'} label={rowsCount} color="primary" />
        </Typography>
      </Stack>
    </Stack>
  );
};
