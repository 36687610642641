import { TreeItem, TreeView } from '@mui/x-tree-view';
import { CardContent, FormControlLabel, FormGroup, Grid, Paper, Stack, Switch, Typography, useTheme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { dateParseformat, defaultColDef, isDataLoading, isDataNull, JSONIsEmpty } from '../../../utils/common';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { ActionsToolBar, AddButton } from '../../../components/grid/ActionsToolbar';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { IconLibraryItem } from '../../../components/icons/IconLibraryItem';
import { CardModal } from '../../../components/card/CardModal';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { FormAutoComplete } from '../../../components/form/FormAutoComplete';
import { deleteCompany, fetchCities, fetchCompanies, fetchCompany, updateCompany } from '../../../store/reducers/registries';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { openDrawer } from '../../../store/reducers/menu';
import { showConfirmDialog } from '../../../store/reducers/errors';

function ExpandMoreIcon() {
  return null;
}

function ChevronRightIcon() {
  return null;
}

const Companies = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  const cities = useSelector((state) => state.registries.cities);
  const companies = useSelector((state) => state.registries.companies);

  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [viewAsTree, setViewAsTree] = useState(false);
  const [custCodeRequired, setCustCodeRequired] = useState(false);

  const { register, control, handleSubmit, getValues, watch, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    if (!isDataLoading(cities)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchCities(payload)).then(() => {});
    }
    if (!isDataLoading(companies)) {
      const payloadCompanies = { body: { mType: 'COMPANY' }, dispatch: dispatch };
      dispatch(fetchCompanies(payloadCompanies)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, cities, companies]);

  //dettaglio

  const onSubmit = (data) => {
    const record = { ...data };
    record.mStartDate = moment(data.mStartDate).format(dateParseformat);
    record.mDurata = data.mDurata && data.mDurata !== 0 ? parseInt(data.mDurata) : 0;
    record.mImporto = parseFloat(data.mImporto);
    record.mType = 'COMPANY';
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateCompany(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === 'change') {
        //niente
        const currentValues = getValues();
        const isCodRequired = !currentValues.mParent ? true : false;
        setCustCodeRequired(isCodRequired);
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, watch]);

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const addAction = () => {
    const payload = { body: { mCompanyID: 0 }, dispatch: dispatch };
    dispatch(fetchCompany(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      const payload = { body: { mCompanyID: selectedRow.mCompanyID }, dispatch: dispatch };
      dispatch(fetchCompany(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const deleteAction = () => {
      const confirm = {
        title: 'Cancellazione azienda',
        message:
          'Attenzione! Questa azione comporterà la cancellazione di tutti gli utenti, le sedi, le sottosedi e gli audit di questa azienda. Procedere?',
        handleHide: () => {},
        handleCancel: () => {},
        handleConfirm: () => {
          const payload = { body: { mCompanyID: selectedRow.mCompanyID }, dispatch: dispatch };
          dispatch(deleteCompany(payload)).then(() => {
            setModalOpen(false);
            setDataLoaded(false);
          });
        }
      };
      dispatch(showConfirmDialog(confirm));
    };

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} />;
  };

  const [columns] = useState([
    { headerName: 'Nome', field: 'mCompanyName' },
    { headerName: 'Indirizzo', field: 'mAddress' },
    { headerName: 'Città', field: 'mCity.mCityName' },
    { headerName: 'Codice Sede', field: 'mCodiceSede' },
    { headerName: 'Società padre', field: 'mParent.mCompanyName' },
    { headerName: 'Codice cliente', field: 'mCodiceCliente' },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolbar' }
  ]);

  const columnTypes = {
    toolButton: { sortable: false, editable: false, filter: '', width: '100', suppressSizeToFit: true }
  };

  if (isDataNull(cities) || isDataLoading(cities)) return <CircularWaiting />;
  if (isDataNull(companies) || isDataLoading(companies)) return <CircularWaiting />;

  const getTreeViewChildren = (companyID) => {
    return companies
      .filter((c) => ((c.mParent ?? {}).mCompanyID ?? -1) === companyID)
      .map((item) => {
        return (
          <TreeItem
            key={item.mCompanyID}
            nodeId={item.mCompanyID}
            label={
              <Stack direction={'row'} alignItems={'center'}>
                {item.mCompanyName}
                <ToolBar node={{ data: item }} />
              </Stack>
            }
            collapseIcon={<IconLibraryItem name={'expand-less'} iconSize={'small'} />}
            expandIcon={<IconLibraryItem name={'expand-more'} iconSize={'small'} />}
            sx={{ marginLeft: theme.spacing(4) }}
          >
            {getTreeViewChildren(item.mCompanyID)}
          </TreeItem>
        );
      });
  };

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Società</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pb: theme.spacing(2) }}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={viewAsTree}
                        onChange={() => {
                          setViewAsTree(!viewAsTree);
                        }}
                      />
                    }
                    label="Visualizzione ad albero"
                  />
                </FormGroup>
                <AddButton addAction={addAction} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {viewAsTree ? (
            <Stack direction={'row'} justifyContent="center">
              <Paper elevation={3} id="treeView" sx={loggedClasses.companiesTreeViewContainer}>
                <TreeView
                  aria-label="company navigator"
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  sx={loggedClasses.companiesTreeView}
                >
                  {companies
                    .filter((c) => !c.mParent)
                    .map((item) => {
                      return (
                        <>
                          <TreeItem
                            key={item.mCompanyID}
                            nodeId={item.mCompanyID}
                            label={
                              <Stack direction={'row'} alignItems={'center'}>
                                {item.mCompanyName}
                                <ToolBar node={{ data: item }} />
                              </Stack>
                            }
                            sx={loggedClasses.companiesTreeViewItem}
                            collapseIcon={<IconLibraryItem name={'expand-less'} iconSize={'small'} />}
                            expandIcon={<IconLibraryItem name={'expand-more'} iconSize={'small'} />}
                          >
                            {getTreeViewChildren(item.mCompanyID)}
                          </TreeItem>
                        </>
                      );
                    })}
                </TreeView>
              </Paper>
            </Stack>
          ) : (
            <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
              {dataLoaded && !isDataLoading(companies) ? (
                <AgGridReact
                  rowData={companies}
                  defaultColDef={defaultColDef}
                  columnDefs={columns}
                  columnTypes={columnTypes}
                  suppressMovableColumns={true}
                  suppressCellFocus={true}
                  pagination={true}
                  rowSelection={'single'}
                  getRowHeight={() => gridRowHeight}
                />
              ) : (
                <CircularWaiting />
              )}
            </div>
          )}
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio società'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          {!JSONIsEmpty(record) ? (
            <>
              <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="Nome"
                    control={control}
                    record={record}
                    register={register}
                    field="mCompanyName"
                    required={true}
                    maxLength={100}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="PIVA"
                    control={control}
                    record={record}
                    register={register}
                    field="mPartitaIVA"
                    required={false}
                    maxLength={12}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="CF"
                    control={control}
                    record={record}
                    register={register}
                    field="mCodiceFisale"
                    required={false}
                    maxLength={16}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="Indirizzo"
                    control={control}
                    record={record}
                    register={register}
                    field="mAddress"
                    required={true}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="CAP"
                    control={control}
                    record={record}
                    register={register}
                    field="mCAP"
                    required={true}
                    maxLength={5}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormAutoComplete
                    label="Città"
                    control={control}
                    record={record}
                    register={register}
                    field="mCity"
                    joinField="mCityID"
                    values={cities}
                    idField="mCityID"
                    labelField="mCompleteName"
                    selectedValue="id"
                    required={true}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="E-mail"
                    control={control}
                    record={record}
                    register={register}
                    field="mMail"
                    required={true}
                    maxLength={100}
                    pattern={'email'}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="PEC"
                    control={control}
                    record={record}
                    register={register}
                    field="mLegalMail"
                    required={!record.mParent}
                    maxLength={100}
                    pattern={'email'}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="Telefono"
                    control={control}
                    record={record}
                    register={register}
                    field="mTelefono"
                    required={false}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="Codice Sede"
                    control={control}
                    record={record}
                    register={register}
                    field="mCodiceSede"
                    required={true}
                    maxLength={45}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormAutoComplete
                    label="Centro/Sede del gruppo"
                    control={control}
                    record={record}
                    register={register}
                    field="mParent"
                    joinField="mCompanyID"
                    values={companies}
                    idField="mCompanyID"
                    labelField="mCompanyName"
                    selectedValue="id"
                    required={false}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <FormTextBox
                    label="Codice Cliente"
                    control={control}
                    record={record}
                    register={register}
                    field="mCodiceCliente"
                    required={custCodeRequired}
                    maxLength={5}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <CircularWaiting />
          )}
        </CardContent>
      </CardModal>
    </>
  );
};

export default Companies;
