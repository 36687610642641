import React, { lazy } from 'react';
import Loadable from 'components/Loadable';
import LoggedLayout from 'layout/Logged';
import Companies from '../pages/logged/registries/Companies';
import Domains from '../pages/logged/registries/Domains';
import Profiles from '../pages/logged/registries/Profiles';
import SystemFunctions from '../pages/logged/registries/SystemFunctions';
import Templates from '../pages/logged/templates/Templates';
import Users from '../pages/logged/registries/Users';
import Profile from '../pages/logged/auth/Profile';
import { DomainContainer } from './LocationRenderer';
import SystemParameters from '../pages/logged/registries/SystemParamters';
import FillAuditByGrid from '../pages/logged/audits/FillAuditByGrid';
import Products from '../pages/logged/templates/Products';
import Audits from '../pages/logged/audits/Audits';
import { ConnectedUsers } from '../pages/logged/controlPanel/components/ConnectedUsers';
import Sites from '../pages/logged/registries/Sites';
import Departments from '../pages/logged/registries/Departments';
import ItemizedLists from '../pages/logged/registries/ItemizedLists';
import { ControlPanel } from '../pages/logged/controlPanel/ControlPanel';
import { ReportsConfiguration } from '../pages/logged/reporting/ReportsConfiguration';

const HomePage = Loadable(lazy(() => import('pages/logged/Home')));

const registriesRoutes = [
  { path: '/Registries/Companies', component: <Companies />, exact: true },
  { path: '/Registries/ConnectedUsers', component: <ConnectedUsers />, exact: true },
  { path: '/Registries/Departments', component: <Departments />, exact: true },
  { path: '/Registries/Domains', component: <Domains />, exact: true },
  { path: '/Registries/ItemizedLists', component: <ItemizedLists />, exact: true },
  { path: '/Registries/Profiles', component: <Profiles />, exact: true },
  { path: '/Registries/Sites', component: <Sites />, exact: true },
  { path: '/Registries/SystemParameters', component: <SystemParameters />, exact: true },
  { path: '/Registries/SystemFunctions', component: <SystemFunctions />, exact: true },
  { path: '/Registries/Users', component: <Users />, exact: true }
];

const templatesRoutes = [
  { path: '/Templates/Audits', component: <Audits />, exact: true },
  { path: '/Templates/Products', component: <Products />, exact: true },
  { path: '/Templates/Templates', component: <Templates />, exact: true },
  { path: '/Templates/FillAuditByGrid', component: <FillAuditByGrid />, exact: true }
];

const controlPanelRoutes = [{ path: '/ControlPanel/Home', component: <ControlPanel />, exact: true }];

const reportingRoutes = [{ path: '/Reporting/ReportsConfiguration', component: <ReportsConfiguration />, exact: true }];

const authRoutes = [{ path: '/User/Profile', component: <Profile />, exact: true }];

const LoggedRoutes = {
  path: '/',
  element: <LoggedLayout />,
  children: [
    {
      path: '/',
      element: <HomePage />
    },
    {
      path: '/Home',
      element: <HomePage />
    },
    {
      path: '/ControlPanel/*',
      element: <DomainContainer title={'Pannello di controllo'} routes={controlPanelRoutes} />
    },
    {
      path: '/User/*',
      element: <DomainContainer title={'Utente'} routes={authRoutes} />
    },
    {
      path: '/Registries/*',
      element: <DomainContainer title={'Anagrafiche'} routes={registriesRoutes} />
    },
    {
      path: '/Reporting/*',
      element: <DomainContainer title={'Reporting'} routes={reportingRoutes} />
    },
    {
      path: '/Templates/*',
      element: <DomainContainer title={'Gestione Audit'} routes={templatesRoutes} />
    }
  ]
};

export default LoggedRoutes;
