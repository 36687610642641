import { Chip, Grid, Stack, Step, StepLabel, Stepper, styled, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { loggedClassNames } from '../../../../themes/Styles';
import { useDispatch } from 'react-redux';
import { CircularWaiting } from '../../../../components/waitings/CircularWaiting';
import { isDataNull } from '../../../../utils/common';
import FillAuditBlock from './FillAuditBlock';
import { openDrawer } from '../../../../store/reducers/menu';
import { beginAudit, calculateAudit, fetchAudit, fetchTemplate, updateAnswer } from '../../../../store/reducers/templates';
import { showCustomAlert } from '../../../../store/reducers/errors';

const StyledChip = styled(Chip)(() => ({
  '& .MuiChip-label': {
    fontSize: '2.0rem'
  }
}));

const FillAudit = ({ auditID, auditorEmail }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const isWeb = auditorEmail !== null;

  const dispatch = useDispatch();

  const [auditRecord, setAuditRecord] = useState(null);
  const [valid, setValid] = React.useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isToDevelope, setIsToDevelope] = useState(false);
  const [isAuditLoading, setIsAuditLoading] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [template, setTemplate] = useState(null);
  const [blocks, setBlocks] = useState([]);
  const [activeBlockIndex, setActiveBlockIndex] = useState(-1);
  const [activeBlock, setActiveBlock] = useState(null);
  const [answersRecord, setAnswersRecord] = useState({});

  // TODO verificare che l'audit non è stato chiuso

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    const payload = { body: { mAuditID: auditID }, dispatch: dispatch };
    dispatch(fetchAudit(payload)).then((resFetch) => {
      if (!resFetch.payload.result) {
        const message = {
          severity: 'error',
          message: "Errore durante il recupero dell'audit"
        };
        dispatch(showCustomAlert(message));
        return;
      }
      const rec = { ...resFetch.payload.result };
      if (rec.mAuditTemplate !== null) {
        const payloadTemplate = { body: { mAuditTemplateID: rec.mAuditTemplate.mAuditTemplateID }, dispatch: dispatch };
        dispatch(fetchTemplate(payloadTemplate)).then((res) => {
          setTemplate(res.payload.result);
        });
      }
      const newAuditRec = {
        mCompany: { ...rec.mCompany },
        mAuditTemplate: {
          mAuditTemplateID: rec.mAuditTemplate.mAuditTemplateID,
          mProduct: {
            mProductID: rec.mAuditTemplate.mProduct.mProductID
          }
        },
        mAudit: { ...rec }
      };
      setAuditRecord(newAuditRec);
      setIsToDevelope(true);
      setDataLoaded(true);
      setValid(true);
      setIsClosed(!newAuditRec.mCanCompile);
    });
  }, [dispatch, dataLoaded, auditRecord, auditID]);

  const buildAnswers = (arrBlocks) => {
    let newAnswersRecord = {};
    arrBlocks.map((b) => {
      const questions = b.mListOfQuestionTemplate ?? [];
      questions.map((q) => {
        const answer = q.mAnswer;
        if (!answer) {
          //nuovo audit o risposta non data
          newAnswersRecord['q-' + q.mQuestionTemplateID] = null;
        } else {
          const answerType = q.mAnswerTypeID;
          if (answerType === 1 || answerType === 2) {
            //itemized && boolean
            newAnswersRecord['q-' + q.mQuestionTemplateID] = answer.mAnswerItemID;
          } else if (answerType === 3 || answerType === 4) {
            //numeric && text
            newAnswersRecord['q-' + q.mQuestionTemplateID] = answer.mAnswerValue;
          }
        }
      });
    });
    setAnswersRecord(newAnswersRecord);
  };

  useEffect(() => {
    if (!dataLoaded) return;
    if (!isToDevelope) return;
    if (isStarted) return;
    if (!auditRecord.mAudit) return;
    if (!auditRecord.mAudit.mAuditID) return;
    if (auditRecord.mAudit.mAuditID <= 0) return;
    let newRecord = { ...auditRecord };
    const bodyBegin = { ...newRecord.mAudit };
    const payloadBegin = { body: bodyBegin, dispatch: dispatch };
    dispatch(beginAudit(payloadBegin)).then((resBegin) => {
      if (!resBegin.payload.result) {
        const message = {
          severity: 'error',
          message: "Errore durante l'inizializzazione dell'audit"
        };
        dispatch(showCustomAlert(message));
        return;
      }
      const auditTemplate = resBegin.payload.result.mAuditTemplate;
      newRecord.mAudit.mAuditTemplate = auditTemplate;
      const arrBlocks = auditTemplate ? auditTemplate.mListOfBlock ?? [] : [];
      setBlocks(arrBlocks);
      buildAnswers(arrBlocks);
      setActiveBlockIndex(0);
      setAuditRecord(newRecord);
      setIsStarted(true);
      setIsToDevelope(false);
    });
  }, [auditRecord, dataLoaded, dispatch, isStarted, isToDevelope]);

  useEffect(() => {
    if (blocks.length === 0) return;
    setActiveBlock(blocks[activeBlockIndex]);
  }, [blocks, activeBlockIndex]);

  const isFirstBlock = blocks.length > 0 && activeBlockIndex === 0;
  const isLastBlock = blocks.length > 0 && activeBlockIndex === blocks.length - 1;

  const handleResetAudit = () => {
    setActiveBlockIndex(0);
  };

  const handlePrevBlock = () => {
    if (activeBlockIndex <= 0) return;
    setActiveBlockIndex((prevState) => prevState - 1);
  };

  const handleNextBlock = () => {
    setActiveBlockIndex((prevActiveStep) => prevActiveStep + 1);
  };

  const handleFinishAudit = () => {
    //richiamo il calcolo dell'audit
    const bodyCalculate = {
      mAuditID: auditRecord.mAudit.mAuditID
    };
    const payloadCalc = { body: bodyCalculate, dispatch: dispatch };
    setIsAuditLoading(true);
    dispatch(calculateAudit(payloadCalc)).then(() => {
      setIsAuditLoading(false);
      setIsCompleted(true);
    });
  };

  const setAnswerValue = (field, value) => {
    let newAnswersRecord = { ...answersRecord };
    newAnswersRecord[field] = value;
    setAnswersRecord(newAnswersRecord);
  };

  const saveAnswerValue = (question, value) => {
    const answerType = question.mAnswerTypeID;
    let bodyAnswer = {
      mAnswerID: (question.mAnswer ?? {}).mAnswerID ?? 0,
      mAuditID: auditID,
      mQuestionTemplateID: question.mQuestionTemplateID
    };
    if (answerType === 1 || answerType === 2) {
      //itemized && boolean
      bodyAnswer.mAnswerItemID = (question.mListOfAnswerItem ?? []).find((a) => a.mAnswerItemID === value).mAnswerItemID;
    } else if (answerType === 3 || answerType === 4) {
      //numeric && text
      bodyAnswer.mAnswerValue = value;
    }
    const payloadAnswer = { body: bodyAnswer, dispatch: dispatch };
    dispatch(updateAnswer(payloadAnswer)).then(() => {
      setAnswerValue(question.mAnswerTypeID, value);
    });
  };

  // const isFormValid =
  //   !isDataNull(auditRecord) &&
  //   !isDataNull(auditRecord.mAuditTemplate) &&
  //   !isDataNull(auditRecord.mAuditTemplate.mProduct) &&
  //   (auditRecord.mAuditTemplate.mAuditTemplateID ?? 0) > 0 &&
  //   (auditRecord.mAuditTemplate.mProduct.mProductID ?? 0) > 0;

  if (auditID === -1) {
    return (
      <Grid container spacing={0} direction={'row'} display={'flex'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Parametro questionario non valido</Typography>
        </Grid>
      </Grid>
    );
  }

  let auditName = '';
  let companyName = '';
  if (template && auditRecord) {
    if (template) auditName = template.mAuditTemplateName ?? '';
    if (auditRecord.mCompany) companyName = auditRecord.mCompany.mCompanyName ?? '';
  }

  if (!valid) return <></>;

  if (isDataNull(auditRecord)) return <CircularWaiting />;

  return (
    <>
      <Grid container spacing={1} direction={'row'} display={'flex'} justifyContent={'center'} alignItems={'flex-start'}>
        <Grid item xs={12} sm={12} md={8}>
          <Stack display={'flex'} direction={'row'} spacing={theme.spacing(1)}>
            <StyledChip variant={'filled'} color={'primary'} label={auditName} size={'large'} />
            <StyledChip variant={'filled'} color={'info'} label={companyName} size={'large'} />
          </Stack>
        </Grid>
        {isClosed && (
          <Grid item xs={12} sm={12} md={8} sx={loggedClasses.auditContainer} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Typography variant="h2">Attenzione! Il questionario è stato chiuso dal responsabile dell&apos;audit.</Typography>
          </Grid>
        )}
        {!isClosed && isToDevelope && <CircularWaiting />}
        {!isClosed && isStarted && !isCompleted && blocks.length > 0 && (
          <Grid item xs={12} sm={12} md={8} sx={loggedClasses.auditPaginator}>
            <Stack spacing={theme.spacing(1)} direction={'row'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Stepper activeStep={activeBlockIndex} sx={loggedClasses.auditStepper}>
                {blocks.map((block, index) => {
                  const stepProps = {};
                  const labelProps = {};
                  return (
                    <Step key={'block-' + index} {...stepProps}>
                      <Tooltip title={block.mBlockTitle} placement="bottom" color={'primary'}>
                        <StepLabel {...labelProps}>{}</StepLabel>
                      </Tooltip>
                    </Step>
                  );
                })}
              </Stepper>
            </Stack>
          </Grid>
        )}
        {!isClosed && isStarted && (
          <Grid item xs={12} sm={12} md={8} sx={loggedClasses.auditContainer}>
            <FillAuditBlock
              isWeb={isWeb}
              activeBlock={activeBlock}
              isAuditLoading={isAuditLoading}
              answersRecord={answersRecord}
              isCompleted={isCompleted}
              isFirstBlock={isFirstBlock}
              isLastBlock={isLastBlock}
              saveAnswerValue={saveAnswerValue}
              handleResetAudit={handleResetAudit}
              handlePrevBlock={handlePrevBlock}
              handleFinishAudit={handleFinishAudit}
              handleNextBlock={handleNextBlock}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default FillAudit;
