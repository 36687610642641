import { CardContent, Grid, Stack, Typography, useTheme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { columnTypes, defaultColDef, isDataLoading, isDataNull } from '../../../utils/common';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { ActionsToolBar, AddButton } from '../../../components/grid/ActionsToolbar';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { FormIconPicker } from '../../../components/form/FormIconPicker';
import { CardModal } from '../../../components/layout/CardModal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDomain, fetchDomain, fetchDomains, updateDomain } from '../../../store/reducers/registries';
import { openDrawer } from '../../../store/reducers/menu';

const Domains = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  const domains = useSelector((state) => state.registries.domains);

  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    if (!isDataLoading(domains)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchDomains(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, domains]);

  //dettaglio

  const onSubmit = (data) => {
    const record = { ...data };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateDomain(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const addAction = () => {
    const payload = { body: { mDomainID: 0 }, dispatch: dispatch };
    dispatch(fetchDomain(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      const payload = { body: { mDomainID: selectedRow.mDomainID }, dispatch: dispatch };
      dispatch(fetchDomain(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const deleteAction = () => {
      const payload = { body: { ...selectedRow }, dispatch: dispatch };
      dispatch(deleteDomain(payload)).then(() => {
        setDataLoaded(false);
      });
    };

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} />;
  };

  const [columns] = useState([
    { headerName: 'Nome', field: 'mDomainName' },
    { headerName: '# Schermate', field: 'mScreenNumber' },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolbar' }
  ]);

  if (isDataNull(domains) || isDataLoading(domains)) return <CircularWaiting />;

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Domini funzionali</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pb: theme.spacing(2) }}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)}
                     sx={{ pt: theme.spacing(1) }}>
                <AddButton addAction={addAction} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            {dataLoaded && !isDataLoading(domains) ? (
              <AgGridReact
                rowData={domains}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio dominio funzionale'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'}
                alignItems={'flex-start'}>
            <Grid item xs={12}>
              <FormTextBox
                label="Nome"
                control={control}
                record={record}
                register={register}
                field="mDomainName"
                required={true}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Sottotitolo"
                control={control}
                record={record}
                register={register}
                field="mDomainMenuSubTitle"
                required={true}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12}>
              <FormIconPicker
                label="Icona"
                control={control}
                record={record}
                register={register}
                field="mImageKey"
                joinField=""
                required={true}
                disabled={false}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
    </>
  );
};

export default Domains;
