import { cloneDeep } from 'lodash';
import moment from 'moment';
import React from 'react';
import { TextGridPopover } from '../components/grid/TextPopover';
import { StatusBadge } from '../components/badges/StatusBadge';

export const intDateTimeFormat = 'YYYYMMDDHHmm';
export const nullDateTime = '01/01/0001 00:00:00';
export const nullDate = '0001-01-01T00:00:00';
export const maxDate = '9999-12-31T23:59:59';
export const dateParseformat = 'YYYY-MM-DDTHH:mm:ss';
export const shortDateformat = 'DD/MM/YYYY';
export const emptyCompleteName = 'NON IMPOSTATO';
export const SYSTEM_ADMIN = 'ADMIN';
export const CUSTOMER_ADMIN = 'CUSTOMER_ADMIN';
export const SCHEMA_ADMIN = 'SCHEMA_MANAGER';
export const AUDITOR = 'AUDITOR';
export const SYSTEM_PARAMETERS_GROUP_LIST_ID = 50;

export const JSONClone = (obj) => {
  return cloneDeep(obj); //shallow copy
};

export const getRandomArbitrary = (min, max) => {
  return parseInt(Math.random() * (max - min)) + min;
};

export const firstLetter = (word) => {
  return word === '' ? word : Array.from(word)[0];
};

export const emptyJSON = {};

export const JSONIsEmpty = (obj) => {
  return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
};

export const floatToString = (value) => {
  return parseFloat(value).toFixed(2).toString().replace('.', ',') ?? '0.00';
};

export const parseMoney = (value) => {
  if (!value) return null;
  value = value.toString().replace(',', '.');
  return parseFloat(parseFloat(value).toFixed(2));
};

export const floatToMoneyString = (value) => {
  return '€ ' + parseFloat(value).toFixed(2).toString().replace('.', ',') ?? '0.00';
};

export const moneyStringToFloat = (value) => {
  return parseFloat(value.toString().replace(',', '.')) ?? 0.0;
};

export const moneyColumnRenderer = (props) => {
  return '€ ' + floatToString(props.value);
};

export const floatColumnRenderer = (props) => {
  return floatToString(props.value);
};

export const booleanRenderer = (props) => {
  if ((props.value ?? false) === true) {
    return 'SI';
  } else {
    return 'NO';
  }
};

export const onLineRendererWithBadge = (props) => {
  if ((props.value ?? '').toLowerCase() === 'online') {
    return <StatusBadge label={'OnLine'} color={'success'} fullWidth={true} minWidth={45} size={'small'} />;
  } else {
    return <StatusBadge label={'OffLine'} color={'error'} fullWidth={true} minWidth={45} size={'small'} />;
  }
};

export const activeRendererWithBadge = (props) => {
  if ((props.value ?? '').toLowerCase() === 'attivo') {
    return <StatusBadge label={'SI'} color={'success'} fullWidth={false} minWidth={45} width={45} size={'small'} />;
  } else {
    return <StatusBadge label={'NO'} color={'error'} fullWidth={false} minWidth={45} width={45} size={'small'} />;
  }
};

export const auditStatusRendererWithBadge = (props) => {
  if ((props.value ?? '').toLowerCase() !== 'terminato') {
    return <StatusBadge label={props.value.toUpperCase()} color={'success'} fullWidth={false} minWidth={120} width={120} size={'small'} />;
  } else {
    return <StatusBadge label={props.value.toUpperCase()} color={'error'} fullWidth={false} minWidth={120} width={120} size={'small'} />;
  }
};

export const logingStatusRenderer = (props) => {
  const status = props.node.data.mSessionStatus ?? '';
  const color = status.toUpperCase() === 'CONNESSO' ? 'success' : 'error';
  return <StatusBadge label={status} color={color} fullWidth={false} minWidth={130} width={130} size={'small'} />;
};

export const booleanRendererWithBadge = (props) => {
  if ((props.value ?? false) === true) {
    return <StatusBadge label={'SI'} color={'success'} fullWidth={false} minWidth={45} width={45} size={'small'} />;
  } else {
    return <StatusBadge label={'NO'} color={'error'} fullWidth={false} minWidth={45} width={45} size={'small'} />;
  }
};

export const booleanMatcher = ({ filterOption, value, filterText }) => {
  const textValue = value === 'true' ? 'SI' : 'NO';
  const filterTextToCompare = filterText.toUpperCase();
  if (filterText == null) {
    return false;
  }
  let index;
  switch (filterOption) {
    case 'contains':
      return textValue.indexOf(filterTextToCompare) >= 0;
    case 'notContains':
      return textValue.indexOf(filterTextToCompare) < 0;
    case 'equals':
      return textValue === filterTextToCompare;
    case 'notEqual':
      return textValue !== filterTextToCompare;
    case 'startsWith':
      return textValue.indexOf(filterTextToCompare) === 0;
    case 'endsWith':
      index = textValue.lastIndexOf(filterTextToCompare);
      return index >= 0 && index === textValue.length - filterTextToCompare.length;
    default:
      // should never happen
      console.warn('invalid filter type');
      return false;
  }
};

export const nameWithBadgeRenderer = (name, lastname, color) => {
  const label = getCompleteName(name, lastname);
  return <StatusBadge label={label} color={color ?? 'info'} fullWidth={true} minWidth={45} size={'small'} />;
};

export const textWithBadgeRenderer = (text, color) => {
  return <StatusBadge label={text} color={color ?? 'info'} fullWidth={true} minWidth={45} size={'small'} />;
};

export const dateColumnRenderer = (props) => {
  return moment(props.value).format('DD/MM/YYYY');
};

//2023-03-06T11:28:31
export const dateTimeColumnRenderer = (props) => {
  const val = moment(props.value).format('DD/MM/YYYY HH:mm:ss');
  return val !== nullDateTime ? val : '';
};

export const dateTimeShortColumnRenderer = (props) => {
  const val = moment(props.value).format('DD/MM/YYYY HH:mm');
  return val !== nullDateTime ? val : '';
};

export const timeColumnRenderer = (props) => {
  const val = moment(props.value).format('HH:mm:ss');
  return val !== nullDateTime ? val : '';
};

export const timeUtcColumnRenderer = (props) => {
  const val = moment(props.value).utc().format('HH:mm:ss');
  return val !== nullDateTime ? val : '';
};

export const weekdayRenderer = (props) => {
  const val = (props.value ?? '').toString();
  const foundElem = daysWithName.find((item) => item.mDayID.toString() === val);
  return (foundElem ?? {}).mDayName ?? '-';
};

export const dateFormatter = (params) => {
  if (!params) return '';
  if (!params.value) return '';
  // let dt = params.value.toString().replace("T00:00:00", "");
  // return moment(dt, "YYYY-MM-DD").format("DD/MM/YYYY");
  return moment(params.value).format('DD/MM/YYYY');
};

export const timeFormatter = (params) => {
  if (!params) return '';
  if (!params.value) return '';
  return moment(params.value).format('HH:mm');
};

export const textPopoverRenderer = (params) => {
  return <TextGridPopover extendedText={params.value} />;
};

export const empty_slot = {
  timeBegin: null,
  timeEnd: null,
  duration: null,
  isCurrent: false,
  isLast: false,
  visitLines: []
};

export const defaultColDef = {
  sortable: true,
  editable: false,
  resizable: false,
  wrapText: true,
  autoHeight: true,
  filter: 'agTextColumnFilter',
  floatingFilter: true,
  flex: 1
};

export const columnTypes = {
  flex1: { flex: 1 },
  flex2: { flex: 2 },
  flex3: { flex: 3 },
  flex4: { flex: 4 },
  statusColumn: { sortable: false, filter: '', suppressSizeToFit: true, flex: 1 },
  toolBar: { sortable: false, filter: '', suppressSizeToFit: false, width: 250, flex: 2 },
  toolButton: { sortable: false, filter: '', suppressSizeToFit: false, width: 100 },
  noFilter: { sortable: true, filter: '', suppressSizeToFit: false, autoHeight: true }
};

export const gridLoadingOverlay =
  '<div style="height:100px; width:100px; background: url(https://ag-grid.com/images/ag-grid-loading-spinner.svg) center / contain no-repeat; margin: 0 auto;"></div>';

export const getCompleteName = (name, lastname) => {
  name = name ?? '';
  lastname = lastname ?? '';
  if (name === '' && lastname === '') {
    return emptyCompleteName;
  }
  return lastname.toUpperCase() + ' ' + name.toUpperCase();
};

export const getCompleteShortName = (name, lastname) => {
  name = name ?? '';
  lastname = lastname ?? '';
  if (name === '' && lastname === '') {
    return emptyCompleteName;
  }
  return lastname.toUpperCase() + ' ' + name.toUpperCase().charAt(0) + '.';
};

export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = (name, width, height) => {
  return {
    sx: {
      bgColor: stringToColor(name),
      width: width,
      height: height
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  };
};

export const isDataNull = (arrData) => {
  return arrData === null;
};

export const isDataEmpty = (arrData) => {
  return (arrData ?? []).length === 0;
};

export const isDataLoading = (arrData) => {
  return arrData === 'loading';
};

export const setDataLoading = (setArrData) => {
  setArrData('loading');
};

export const downloadPDFListOfByte = (arrayBuffer, name) => {
  let a = document.createElement('a');
  a.download = name;
  // a.href = URL.createObjectURL(blob);
  a.setAttribute('href', 'data:application/pdf;base64,' + [arrayBuffer]);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadTextListOfByte = (arrayBuffer, name) => {
  let a = document.createElement('a');
  a.download = name;
  // a.href = URL.createObjectURL(blob);
  a.setAttribute('href', 'data:text/plain;base64,' + [arrayBuffer]);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const downloadGenericListOfByte = (arrayBuffer, name) => {
  let a = document.createElement('a');
  a.download = name;
  // a.href = URL.createObjectURL(blob);
  a.setAttribute('href', 'data:application/octet-stream;base64,' + [arrayBuffer]);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const dateIsNull = (pDate) => {
  const format = 'YYYY-MM-DDTHH:mm:ss';
  if (!pDate) return true;
  return pDate ? moment(pDate).format(format) === nullDate : true;
};

export const normalizeDateTime = (pDate) => {
  return moment(pDate);
};

export const getConfigKey = (key) => {
  const keyValue = window.app && window.app.env[key];
  console.log(keyValue);
  return keyValue;
};

export const daysWithName = [
  { mDayID: 1, mDayName: 'LUNEDÌ' },
  { mDayID: 2, mDayName: 'MARTEDÌ' },
  { mDayID: 3, mDayName: 'MERCOLEDÌ' },
  { mDayID: 4, mDayName: 'GIOVEDÌ' },
  { mDayID: 5, mDayName: 'VENERDÌ' },
  { mDayID: 6, mDayName: 'SABATO' },
  { mDayID: 7, mDayName: 'DOMENICA' }
];

export const answerType = [
  { mAnswerTypeID: 1, mAnswerTypeName: 'ItemizedList' },
  { mAnswerTypeID: 2, mAnswerTypeName: 'Boolean' },
  { mAnswerTypeID: 3, mAnswerTypeName: 'NumericUpDown' },
  { mAnswerTypeID: 4, mAnswerTypeName: 'Text' }
];

export const defaultEditorOptions = {
  options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'remove', 'history']
};
