import { Grid, Typography } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import {
  columnTypes,
  dateTimeColumnRenderer,
  defaultColDef,
  isDataLoading,
  isDataNull,
  logingStatusRenderer
} from '../../../../utils/common';
import { CircularWaiting } from '../../../../components/waitings/CircularWaiting';
import { gridRowHeight, gridSizeStyle } from '../../../../themes/Styles';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConnectedUsers } from '../../../../store/reducers/controPanel';

export const ConnectedUsers = () => {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const records = useSelector((state) => state.controlPanel.connectedUsers);

  useEffect(() => {
    if (dataLoaded) return;
    if (!isDataLoading(records)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchConnectedUsers(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, records]);

  const [columns] = useState([
    { headerName: 'Nome', field: 'mFirstName' },
    { headerName: 'Cognome', field: 'mLastName' },
    { headerName: 'Login', field: 'mLoginDate', cellRenderer: dateTimeColumnRenderer },
    { headerName: 'Ultimo rilevamento', field: 'mLastActvity', cellRenderer: dateTimeColumnRenderer },
    { headerName: 'Stato', field: 'mSessionStatus', type: 'flex1', cellRenderer: logingStatusRenderer },
    { headerName: 'Fine sessione', field: 'mExpirationDate', cellRenderer: dateTimeColumnRenderer }
  ]);

  if (isDataNull(records) || isDataLoading(records)) return <CircularWaiting />;

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Utenti connessi</Typography>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            {dataLoaded && !isDataLoading(records) ? (
              <AgGridReact
                rowData={records}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};
