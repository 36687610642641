import { CardContent, Grid, Stack, useTheme } from '@mui/material';
import { gridExtraSizeStyle, gridRowHeight, loggedClassNames } from '../../../../themes/Styles';
import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { columnTypes, defaultColDef, isDataLoading } from '../../../../utils/common';
import { CardModal } from '../../../../components/layout/CardModal';
import { deleteTemplateBlock, fetchTemplateBlock, fetchTemplateBlocks, updateTemplateBlock } from '../../../../store/reducers/templates';
import { useDispatch, useSelector } from 'react-redux';
import { ActionsToolBar, AddButton } from '../../../../components/grid/ActionsToolbar';
import { useForm } from 'react-hook-form';
import { FormTextBox } from '../../../../components/form/FormTextBox';
import { BlockQuestions } from './BlockQuestions';
import { CircularWaiting } from '../../../../components/waitings/CircularWaiting';

export const TemplateBlocks = ({ template, open, onClose }) => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  let blocks = useSelector((state) => state.templates.templateBlocks);

  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [questionsRecord, setQuestionsRecord] = useState(null);
  const [questionsModalOpen, setQuestionsModalOpen] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    if (!isDataLoading(blocks)) {
      const payload = { body: { mAuditTemplate: { ...template } }, dispatch: dispatch };
      dispatch(fetchTemplateBlocks(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, blocks, template]);

  //dettaglio

  const saveBlock = (block) => {
    const record = {
      ...block,
      mAuditTemplate: { ...template }
    };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateTemplateBlock(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onSubmit = (data) => {
    saveBlock(data);
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onModalClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const addAction = () => {
    const payload = { body: { mAuditTemplate: { ...template }, mBlockID: 0 }, dispatch: dispatch };
    dispatch(fetchTemplateBlock(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      const payload = { body: { mAuditTemplate: { ...template }, mBlockID: selectedRow.mBlockID }, dispatch: dispatch };
      dispatch(fetchTemplateBlock(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const deleteAction = () => {
      const payload = { body: { mAuditTemplate: { ...template }, mBlockID: selectedRow.mBlockID }, dispatch: dispatch };
      dispatch(deleteTemplateBlock(payload)).then(() => {
        setDataLoaded(false);
      });
    };

    const questionsAction = () => {
      setQuestionsRecord(selectedRow);
      setQuestionsModalOpen(true);
    };

    const moveUp = () => {
      const block = { ...selectedRow };
      block.mOrder = Math.max(block.mOrder - 1, 1);
      saveBlock(block);
    };

    const moveDown = () => {
      const block = { ...selectedRow };
      block.mOrder = Math.min(block.mOrder + 1, blocks.length);
      saveBlock(block);
    };

    const customActions = [
      {
        action: questionsAction,
        disabled: false,
        icon: 'question-mark',
        tooltip: 'Domande/Risposte'
      },
      {
        action: moveUp,
        disabled: false,
        icon: 'arrow-up',
        tooltip: 'Sposta su',
        color: 'primary'
      },
      {
        action: moveDown,
        disabled: false,
        icon: 'arrow-down',
        tooltip: 'Sposta giù',
        color: 'primary'
      }
    ];

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} customActions={customActions} />;
  };

  const columns = [
    { headerName: 'Ordine', field: 'mOrder' },
    { headerName: 'Titolo', field: 'mBlockTitle' },
    { headerName: 'Descrizione', field: 'mBlockDescription' },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolButton' }
  ];

  const onQuestionsClose = () => {
    setQuestionsRecord(false);
    setQuestionsModalOpen(false);
  };

  return (
    <>
      <CardModal modalOpen={open} onClose={onClose} title={'Blocchi del template'} size={'small'}>
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12}>
              <Grid container sx={{ pb: theme.spacing(2) }}>
                <Grid item xs={12}>
                  <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                    <AddButton addAction={addAction} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div id="agReactGrid" className="ag-theme-alpine" style={gridExtraSizeStyle}>
                {dataLoaded && !isDataLoading(blocks) ? (
                  <AgGridReact
                    rowData={blocks}
                    defaultColDef={defaultColDef}
                    columnDefs={columns}
                    columnTypes={columnTypes}
                    suppressMovableColumns={true}
                    suppressCellFocus={true}
                    pagination={true}
                    rowSelection={'single'}
                    getRowHeight={() => gridRowHeight}
                  />
                ) : (
                  <CircularWaiting />
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
      <CardModal
        modalOpen={modalOpen}
        onClose={onModalClose}
        title={'Dettaglio blocco'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={6}>
              <FormTextBox
                label="Titolo"
                control={control}
                record={record}
                register={register}
                field="mBlockTitle"
                required={true}
                maxLength={20}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Descrizione"
                control={control}
                record={record}
                register={register}
                field="mBlockDescription"
                required={true}
                maxLength={200}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
      {questionsModalOpen && (
        <BlockQuestions open={questionsModalOpen} template={template} block={questionsRecord} onClose={onQuestionsClose} />
      )}
    </>
  );
};
