import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CircularWaiting } from '../../../../components/waitings/CircularWaiting';
import UserImage from '../../../../components/user/UserImage';
import { ActionButton } from '../../../../components/buttons/ActionButton';
import { FormTextBox } from '../../../../components/form/FormTextBox';
import { FormActionBar } from '../../../../components/form/FormActionBar';
import UploadUserImage from '../../../../components/user/UploadUserImage';
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer } from '../../../../store/reducers/menu';
import { getLoggedUserProfile, updateLoggedUserProfile, updateLoggedUserProfileImage } from '../../../../store/reducers/auth';

const EditProfile = () => {
  const dispatch = useDispatch();

  const authStatus = useSelector((state) => state.auth.authStatus) ?? {};
  const loggedUser = authStatus.loggedUser ?? {};

  const [record, setRecord] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    const payload = { body: { ...loggedUser, mWithAvatar: true }, dispatch: dispatch };
    dispatch(getLoggedUserProfile(payload)).then(() => {
      setRecord(loggedUser);
      reset(loggedUser);
      setDataLoaded(true);
    });
  }, [dataLoaded, dispatch, loggedUser, reset]);

  const onSubmit = (data) => {
    const body = {
      ...loggedUser,
      ...data
    };
    const payload = { body: { ...body }, dispatch: dispatch };
    dispatch(updateLoggedUserProfile(payload)).then(() => {
      dispatch(getLoggedUserProfile(payload)).then(() => {});
    });
  };

  const onError = (errors, e) => {
    console.log(errors, e);
  };

  const openUploadModal = () => {
    setUploadModalOpen(true);
  };

  const onUploadClose = () => {
    setUploadModalOpen(false);
    setDataLoaded(false);
  };

  const resetImage = () => {
    setIsUploading(true);
    const payload = {
      body: {
        ...loggedUser,
        mImageListOfBytes: ''
      },
      dispatch: dispatch
    };
    dispatch(updateLoggedUserProfileImage(payload)).then(() => {
      setIsUploading(false);
      setDataLoaded(false);
    });
  };

  if (!record) return <CircularWaiting />;

  let workFor = '';
  if (record.mCompany) {
    workFor += (record.mCompany.mCompanyName ?? '') + ' - ';
    workFor += (record.mCompany.mAddress ?? '') + ' - ';
    workFor += (record.mCompany.mCity ?? { mCityName: '-' }).mCityName + ' (';
    workFor += (record.mCompany.mCity ?? { mCityName: '-' }).mTargaProvincia + ')';
  }

  let workAt = '';
  if (record.mWorkSite) {
    workAt += (record.mWorkSite.mCompanyName ?? '') + ' - ';
    workAt += (record.mWorkSite.mAddress ?? '') + ' - ';
    workAt += (record.mWorkSite.mCity ?? { mCityName: '-' }).mCityName + ' (';
    workAt += (record.mWorkSite.mCity ?? { mCityName: '-' }).mTargaProvincia + ')';
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Grid container direction={'row'} spacing={2}>
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant={'h4'}>Dipendente della società</Typography>
                <Typography variant={'h6'}>{workFor}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'h4'}>Lavora presso</Typography>
                <Typography variant={'h6'}>{workAt}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'h4'}>Profilo</Typography>
                <Typography variant={'h6'}>{record.mProfile.mProfileName}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sx={{ minHeight: '250px', border: '1px solid;' }}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {isUploading ?? false ? <></> : <UserImage width={200} height={200} />}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6} justifyContent={'center'}>
                    <ActionButton
                      color="primary"
                      variant="contained"
                      action={openUploadModal}
                      disabled={false}
                      icon={'upload'}
                      iconSize={'small'}
                      label={'Carica immagine'}
                      size={'small'}
                      tooltip={'Upload immagine profilo'}
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid item xs={6} justifyContent={'center'}>
                    <ActionButton
                      color="primary"
                      variant="contained"
                      action={resetImage}
                      disabled={false}
                      icon={'cancel'}
                      iconSize={'small'}
                      label={'Reset immagine'}
                      size={'small'}
                      tooltip={'Reset immagine profilo'}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormTextBox
                  label="Username"
                  control={control}
                  record={record}
                  register={register}
                  field="mUserName"
                  required={true}
                  maxLength={50}
                  pattern={'email'}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextBox
                  label="Nome"
                  control={control}
                  record={record}
                  register={register}
                  field="mFirstName"
                  required={true}
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextBox
                  label="Cognome"
                  control={control}
                  record={record}
                  register={register}
                  field="mLastName"
                  required={true}
                  maxLength={50}
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextBox
                  label="Cellulare"
                  control={control}
                  record={record}
                  register={register}
                  field="mMobilePhone"
                  required={true}
                  maxLength={50}
                />
              </Grid>
              <Grid item xl={6} md={12}>
                <FormActionBar submitLabel={'Salva'} hasCancel={false} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <UploadUserImage isOpen={uploadModalOpen} onClose={onUploadClose} setIsUploading={setIsUploading} />
    </>
  );
};

export default EditProfile;
