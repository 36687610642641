import { CardContent, Grid, Stack, Switch, Typography, useTheme } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { booleanRenderer, columnTypes, defaultColDef, isDataLoading, isDataNull } from '../../../utils/common';
import { FormTextBox } from '../../../components/form/FormTextBox';
import { ActionsToolBar, AddButton } from '../../../components/grid/ActionsToolbar';
import { CircularWaiting } from '../../../components/waitings/CircularWaiting';
import { gridExtraSizeStyle, gridRowHeight, gridSizeStyle, loggedClassNames } from '../../../themes/Styles';
import { CardModal } from '../../../components/layout/CardModal';
import { useDispatch, useSelector } from 'react-redux';
import { addProfileFunction, fetchProfile, fetchProfiles, removeProfileFunction, updateProfile } from '../../../store/reducers/registries';
import { StatusBadge } from '../../../components/badges/StatusBadge';
import { FormCheckBox } from '../../../components/form/FormCheckBox';
import { openDrawer } from '../../../store/reducers/menu';

const Profiles = () => {
  const theme = useTheme();
  const loggedClasses = loggedClassNames(theme);

  const dispatch = useDispatch();
  const profiles = useSelector((state) => state.registries.profiles);

  const [record, setRecord] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [extraRecordId, setExtraRecordId] = useState(-1);
  const [extraRecord, setExtraRecord] = useState({});
  const [extraModalOpen, setExtraModalOpen] = useState(false);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues: record });

  useEffect(() => {
    if (dataLoaded) return;
    dispatch(openDrawer({ drawerOpen: false }));
    if (!isDataLoading(profiles)) {
      const payload = { body: {}, dispatch: dispatch };
      dispatch(fetchProfiles(payload)).then(() => {
        setDataLoaded(true);
      });
    }
  }, [dispatch, dataLoaded, profiles]);

  //dettaglio

  const onSubmit = (data) => {
    const record = { ...data };
    const payload = { body: record, dispatch: dispatch };
    dispatch(updateProfile(payload)).then(() => {
      setModalOpen(false);
      setDataLoaded(false);
    });
  };

  const onError = (errors, e) => {
    console.log(record);
    console.log(errors, e);
  };

  const onClose = () => {
    setModalOpen(false);
    setDataLoaded(false);
  };

  //griglia

  const addAction = () => {
    const payload = { body: { mProfileID: 0 }, dispatch: dispatch };
    dispatch(fetchProfile(payload)).then((res) => {
      setRecord(res.payload.result);
      reset(res.payload.result);
      setModalOpen(true);
    });
  };

  const loadExtraRecord = (recordID) => {
    setExtraRecordId(recordID);
    const payload = { body: { mProfileID: recordID }, dispatch: dispatch };
    dispatch(fetchProfile(payload)).then((res) => {
      setExtraRecord(res.payload.result);
      setExtraModalOpen(true);
    });
  };

  const ToolBar = (props) => {
    let selectedRow = props.node.data;

    const editAction = () => {
      const payload = { body: { mProfileID: selectedRow.mProfileID }, dispatch: dispatch };
      dispatch(fetchProfile(payload)).then((res) => {
        setRecord(res.payload.result);
        reset(res.payload.result);
        setModalOpen(true);
      });
    };

    const deleteAction = () => {
      alert(selectedRow.mProfileID);
    };

    const linkedScreenAction = () => {
      loadExtraRecord(selectedRow.mProfileID);
    };

    const customActions = [
      {
        action: linkedScreenAction,
        disabled: false,
        icon: 'link',
        tooltip: 'Schermate collegate'
      }
    ];

    return <ActionsToolBar hasEdit editAction={editAction} hasDelete deleteAction={deleteAction} customActions={customActions} />;
  };

  const [columns] = useState([
    { headerName: 'Nome', field: 'mProfileName' },
    { headerName: 'Descrizione', field: 'mProfileDescription' },
    { headerName: 'SQLName', field: 'mSQLName' },
    { headerName: 'Abil. reg. ', field: 'mAvailableToRegistration', cellRenderer: booleanRenderer },
    {
      headerName: 'Numero utenti',
      cellRenderer: (props) => {
        return <StatusBadge label={props.node.data.mUsersNumber} color={'info'} fullWidth />;
      },
      type: 'statusColumn',
      maxWidth: 150
    },
    { headerName: 'Azioni', cellRenderer: ToolBar, type: 'toolBar' }
  ]);

  const onExtraClose = () => {
    setExtraModalOpen(false);
    setExtraRecordId(-1);
  };

  const ExtraActionSwitch = (props) => {
    let selectedRow = props.node.data;

    const switchChanged = () => {
      if (selectedRow.mIsEnabled) {
        const payload = {
          body: { mFunctionID: selectedRow.mFunctionID, mProfileID: extraRecordId },
          dispatch: dispatch
        };
        dispatch(removeProfileFunction(payload)).then(() => {
          loadExtraRecord(extraRecordId);
        });
      } else {
        const payload = {
          body: { mFunctionID: selectedRow.mFunctionID, mProfileID: extraRecordId },
          dispatch: dispatch
        };
        dispatch(addProfileFunction(payload)).then(() => {
          loadExtraRecord(extraRecordId);
        });
      }
    };

    if (selectedRow.mIsEnabled) {
      return (
        <Switch
          defaultChecked
          size="small"
          color="secondary"
          onChange={() => {
            switchChanged();
          }}
        />
      );
    } else {
      return (
        <Switch
          size="small"
          color="secondary"
          onChange={() => {
            switchChanged();
          }}
        />
      );
    }
  };

  const columnsExtra = [
    { headerName: 'Nome', field: 'mFunctionName' },
    { headerName: 'Descrizione', field: 'mFunctionDescription' },
    { headerName: 'Dominio', field: 'mDomain.mDomainName' },
    { headerName: '', cellRenderer: ExtraActionSwitch, type: 'toolButton' }
  ];

  if (isDataNull(profiles) || isDataLoading(profiles)) return <CircularWaiting />;

  return (
    <>
      <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12}>
          <Typography variant="h4">Profili</Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={{ pb: theme.spacing(2) }}>
            <Grid item xs={12}>
              <Stack direction={'row'} justifyContent={'flex-end'} spacing={theme.spacing(2)} sx={{ pt: theme.spacing(1) }}>
                <AddButton addAction={addAction} />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div id="agReactGrid" className="ag-theme-alpine" style={gridSizeStyle}>
            {dataLoaded && !isDataLoading(profiles) ? (
              <AgGridReact
                rowData={profiles}
                defaultColDef={defaultColDef}
                columnDefs={columns}
                columnTypes={columnTypes}
                suppressMovableColumns={true}
                suppressCellFocus={true}
                pagination={true}
                rowSelection={'single'}
                getRowHeight={() => gridRowHeight}
              />
            ) : (
              <CircularWaiting />
            )}
          </div>
        </Grid>
      </Grid>
      <CardModal
        modalOpen={modalOpen}
        onClose={onClose}
        title={'Dettaglio profilo'}
        size={'small'}
        formFunctions={{ submitHandler: handleSubmit, onFormSubmit: onSubmit, onFormError: onError }}
      >
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container direction={'row'} spacing={theme.spacing(2)} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12}>
              <FormTextBox
                label="Nome"
                control={control}
                record={record}
                register={register}
                field="mProfileName"
                required={true}
                maxLength={50}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextBox
                label="Descrizione"
                control={control}
                record={record}
                register={register}
                field="mProfileDescription"
                required={true}
                maxLength={500}
              />
            </Grid>
            <Grid item xs={4}>
              <FormTextBox
                label="SQL Name"
                control={control}
                record={record}
                register={register}
                field="mSQLName"
                required={true}
                maxLength={15}
              />
            </Grid>
            {/*<Grid item xs={4}>*/}
            {/*  <FormTextBox*/}
            {/*    label="Sonic price"*/}
            {/*    control={control}*/}
            {/*    record={record}*/}
            {/*    register={register}*/}
            {/*    pattern={"number"}*/}
            {/*    field="mSonicPrice"*/}
            {/*    required={true}*/}
            {/*    maxLength={5}*/}
            {/*  />*/}
            {/*</Grid>*/}
            <Grid item xs={4}>
              <FormCheckBox
                label="Abilitato alla registrazione"
                control={control}
                record={record}
                register={register}
                field="mAvailableToRegistration"
                required={false}
              />
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
      <CardModal modalOpen={extraModalOpen} onClose={onExtraClose} title={'Funzioni del profilo'} size={'small'}>
        <CardContent sx={loggedClasses.detailsContent}>
          <Grid container spacing={0} direction={'row'} justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid item xs={12}>
              <div id="agReactGrid" className="ag-theme-alpine" style={gridExtraSizeStyle}>
                {extraRecord.mListOfFunction ? (
                  <AgGridReact
                    rowData={extraRecord.mListOfFunction}
                    defaultColDef={defaultColDef}
                    columnDefs={columnsExtra}
                    columnTypes={columnTypes}
                    suppressMovableColumns={true}
                    suppressCellFocus={true}
                    pagination={true}
                    rowSelection={'single'}
                    getRowHeight={() => gridRowHeight}
                  />
                ) : (
                  <CircularWaiting />
                )}
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </CardModal>
    </>
  );
};

export default Profiles;
